import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme, useMediaQuery, Select, MenuItem, Menu, IconButton } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: "#3D2E57",
    fontSize: "18px",
    fontWeight: "400",
    "&.MuiTab-root.Mui-selected": {
      backgroundColor: "#3D2E57",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.125rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.65rem",
        width: "50%",
      },
      color: "white",
      textTransform: "none",
      borderRadius: "5px",
    },
    "&.MuiButtonBase-root": {
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.125rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.65rem",
        width: "50%",
      },
      backgroundColor: "rgba(42, 34, 70, 0.1)",
      color: "#3D2E57",
      textTransform: "none",
      borderRadius: "5px",
    },
  },
}));
const MyMenuItem = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "white !important",
      color: "#FF8D2A",
    },
    fontSize: "20px",
    display: "flex",
    alignItems: "center",
    color: "#3D2E57",
  },
})(MenuItem);
export default function OrderPage() {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  const navigate = useNavigate()
  const route = [
    "/orders/list",
    "/orders/pending",
    "/orders/declined",
    "/orders/approved",
  ];
  const location = useLocation();
  const handleClick = (e) => {
    console.log(e);
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (e) => { }
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Effra",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "29px",
          color: "#3D2E57",
          pb: 2,
        }}
      >
        Orders
      </Typography>
      <Box sx={{ borderColor: "divider" }}>
        {!isMobile && <Tabs
          value={location.pathname}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none",
          }}
        >
          <Tab
            classes={tabClasses}
            label="Orders List"
            value={route[0]}
            LinkComponent={Link}
            to={route[0]}
          />
          <Tab
            classes={tabClasses}
            label="Pending Videos"
            value={route[1]}
            LinkComponent={Link}
            to={route[1]}
          />
          <Tab
            classes={tabClasses}
            label="Declined Videos"
            value={route[2]}
            LinkComponent={Link}
            to={route[2]}
          />
          <Tab
            classes={tabClasses}
            label="Approved Videos"
            value={route[3]}
            LinkComponent={Link}
            to={route[3]}
          />
        </Tabs>
        }

        {isMobile &&
          <Box sx={{
           bgcolor:"#3D2E57",
           width:'12%',
           borderRadius:'3px'
          }}>
            <IconButton
            onClick={handleClick} 
            aria-haspopup="true" 
            sx={{color:'white'}}
            aria-expanded={ "true"}>
              
              <MenuIcon />
            </IconButton>
            <Menu
               
               id="demo-positioned-menu"
               aria-labelledby="demo-positioned-button"
               anchorEl={anchorEl}
               open={open}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'top',
                 horizontal: 'left',
               }}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'left',
               }}
             >
              <MyMenuItem  onClick={()=>{
                navigate(route[0])
                handleClose()
                }}>
               Order List
              </MyMenuItem>
              <MyMenuItem  onClick={()=>{
                navigate(route[1])
                handleClose()
                }}>
                Pending Videos
              </MyMenuItem>
              <MyMenuItem  onClick={()=>{
                navigate(route[2])
                handleClose()
                }}>
                Declined Videos
              </MyMenuItem>
              <MyMenuItem  onClick={()=>{
                navigate(route[3])
                handleClose()
                }}>
                Approved Videos
              </MyMenuItem>
            </Menu>
          </Box>
        }
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}
