/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import * as yup from "yup";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserAddress,
  country_dropdown_data,
  SaveAddressList,
} from "../../../redux/action/users";
import LoaderComponent from "../../Loader/LoaderComponent";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Typography,
  FormLabel,
  Skeleton,
  TextField,
  MenuItem,
  Select,
  InputLabel,
} from "@mui/material";
import Toastify from "../../../components/SnackBar/Toastify";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { storage } from "../../../config/storage";

const schema = yup.object().shape({
  recipient_name: yup.string().required("Please enter name"),
  recipient_email: yup
    .string()
    .required("Please enter your email")
    .email("Please enter valid email"),
  recipient_contact: yup
    .string()
    .required("Please enter your phone number")
    .matches(/^[0-9]*$/, "Please enter valid phone number"),
  recipient_dob: yup.string().required("Please enter your Date of birth"),
  recipient_city: yup.string().required("Please enter the city"),
  recipient_country: yup.string().required("Select Country"),
  recipient_address1: yup.string().required("Delivery Address is required"),
  recipient_postcode: yup.string().required("Postcode is required"),
});

const AddnewAddress = ({
  country_dropdown_data,
  getUserAddress,
  EditAddress,
  address_id,
  SaveAddressList,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [country_names, setCountry_names] = useState([]);
  const [loading, setLoading] = useState(false);
  const [country, setCountry] = useState("Select Country");

  const [userData, setUserData] = useState({
    recipient_name: "",
    recipient_email: "",
    recipient_contact: "",
    recipient_dob: "",
    recipient_address1: "",
    recipient_address2: "",
    recipient_city: "",
    recipient_postcode: "",
    recipient_country: country,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    country_dropdown_data().then((res) => {
      setCountry_names(res.data.data.country_list);
    });
    let api_data = {
      address_id: address_id,
    };
    if (address_id) {
      setLoading(true);
      getUserAddress(api_data).then((res) => {
        setLoading(false);
        if (res) {
          const result = res.data.data.user_address;
          setUserData({
            recipient_name: result.recipient_name,
            recipient_email: result.recipient_email,
            recipient_contact: result.recipient_contact,
            recipient_dob: result.recipient_dob,
            recipient_address1: result.recipient_address1,
            recipient_city: result.recipient_city,
            recipient_postcode: result.recipient_postcode,
            recipient_country: result.recipient_country,
            recipient_address2: result.recipient_address2,
          });
          setCountry(result.recipient_country);
        } else {
        }
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: userData,
    validationSchema: schema,
    onSubmit: (value) => {
      setUserData(value);
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  let user_data = storage.fetch.user();
  let user_id = { users_id: user_data.users_id };
  const onSubmit = async (model) => {
    setOpen(true);
    let api_data = { address_id: address_id };
    
    Object.assign(model, api_data, user_id);
    let item = model;
    setLoading(true);
    item.recipient_country = country;
    SaveAddressList(item).then((res) => {
      setLoading(false);
      if (res.data.status) {
        toast.success(res?.data?.message);
        setTimeout(() => {
          navigate("/Users/address", { state: user_data });
      setOpen(false);
    }, 1000);
      } else {
        res?.data?.errors?.map((item) => {
          return toast.error(item);
    });
      setOpen(false);
    }
    });
  };
  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          // "96.82%": "100%",
          minHeight: 800,
          pr: 3,
          pl: 3,
        }}
      >
        <LoaderComponent open={open} />

        <form
          name="RegisterForm"
          onSubmit={formik.handleSubmit}
          schema={schema}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography display="block" sx={Style.typographyStyle}>
            {EditAddress ? "Edit Address" : "Add New Address"}
          </Typography>

          <Box
            sx={{
              width: "100%",
              height: "30%",
              borderRadius: 2,
            }}
          >
            <Box sx={Style.rowBoxStyle}>
              {loading && (
                <Skeleton
                  sx={Style.inputStyle}
                  variant="rectangular"
                  height={50}
                />
              )}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Name
                    <span style={Style.star}>*</span>
                  </FormLabel>

                  <TextField
                    name="recipient_name"
                    value={formik.values.recipient_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    inputProps={{
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                      },
                    }}
                    color="primary"
                    placeholder="Enter recipients name here"
                    sx={{
                      width: "100%",
                      border: "none",
                      // bgcolor: inputBgcolor,
                    }}
                    autoComplete="false"
                  />
                  {formik.errors.recipient_name &&
                  formik.touched.recipient_name ? (
                    <p style={{ color: "red" }}>
                      {formik.errors.recipient_name}
                    </p>
                  ) : null}
                </Box>
              )}

              {loading && (
                <Skeleton
                  sx={Style.inputStyle}
                  variant="rectangular"
                  height={50}
                />
              )}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Date of Birth
                    <span style={Style.star}>*</span>
                  </FormLabel>
                  <TextField
                    name="recipient_dob"
                    value={formik.values.recipient_dob}
                    id="recipient_dob"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="date"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    autoComplete="false"
                    inputProps={{
                      max: new Date().toISOString().split("T")[0],
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                        // bgcolor: inputBgcolor,
                        textTransform: "uppercase",
                      },
                    }}
                    color="primary"
                    placeholder="DD/MM/YYYY"
                    sx={{
                      width: "100%",
                      border: "none",
                    }}
                  />

                  {formik.errors.recipient_dob &&
                  formik.touched.recipient_dob ? (
                    <p style={{ color: "red" }}>
                      {formik.errors.recipient_dob}
                    </p>
                  ) : null}
                </Box>
              )}
            </Box>

            <Box sx={Style.rowBoxStyle}>
              {loading && (
                <Skeleton
                  sx={Style.inputStyle}
                  variant="rectangular"
                  height={50}
                />
              )}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Email
                    <span style={Style.star}>*</span>
                  </FormLabel>
                  <TextField
                    name="recipient_email"
                    value={formik.values.recipient_email}
                    id="recipient_email"
                    autoComplete="false"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    inputProps={{
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                      },
                    }}
                    color="primary"
                    placeholder="Enter recipients email address"
                    sx={{
                      width: "100%",
                      border: "none",
                      // bgcolor: inputBgcolor,
                    }}
                  />
                  {formik.errors.recipient_email &&
                  formik.touched.recipient_email ? (
                    <p style={{ color: "red" }}>
                      {formik.errors.recipient_email}
                    </p>
                  ) : null}
                </Box>
              )}
              {loading && (
                <Skeleton
                  sx={Style.inputStyle}
                  variant="rectangular"
                  height={50}
                />
              )}
              {!loading && (
                <Box sx={Style.inputStyle}>
                  <FormLabel sx={Style.label}>
                    Mobile Number
                    <span style={Style.star}>*</span>
                  </FormLabel>
                  <TextField
                    name="recipient_contact"
                    value={formik.values.recipient_contact}
                    id="recipient_contact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="phone"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    inputProps={{
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                      },
                    }}
                    autoComplete="false"
                    color="primary"
                    placeholder="Enter recipients Mobile no."
                    sx={{
                      width: "100%",
                      border: "none",
                      // bgcolor: inputBgcolor,
                    }}
                  />
                  {formik.errors.recipient_contact &&
                  formik.touched.recipient_contact ? (
                    <p style={{ color: "red" }}>
                      {formik.errors.recipient_contact}
                    </p>
                  ) : null}
                </Box>
              )}
            </Box>
            <>
              <Box sx={Style.rowBoxStyle}>
                {loading && (
                  <Skeleton
                    sx={Style.inputStyle}
                    variant="rectangular"
                    height={50}
                  />
                )}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Delivery address line 1<span style={Style.star}>*</span>
                    </FormLabel>

                    <TextField
                      name="recipient_address1"
                      value={formik.values.recipient_address1}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter delivery address"
                      sx={{
                        width: "100%",
                        border: "none",
                        // bgcolor: inputBgcolor,
                      }}
                      autoComplete="false"
                    />
                    {formik.errors.recipient_address1 &&
                    formik.touched.recipient_address1 ? (
                      <p style={{ color: "red" }}>
                        {formik.errors.recipient_address1}
                      </p>
                    ) : null}
                  </Box>
                )}

                {loading && (
                  <Skeleton
                    sx={Style.inputStyle}
                    variant="rectangular"
                    height={50}
                  />
                )}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Delivery address line 2
                    </FormLabel>

                    <TextField
                      name="recipient_address2"
                      value={formik.values.recipient_address2}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter delivery address"
                      sx={{
                        width: "100%",
                        border: "none",
                        // bgcolor: inputBgcolor,
                      }}
                      autoComplete="false"
                    />
                  </Box>
                )}
              </Box>
              <Box sx={Style.rowBoxStyle}>
                {loading && (
                  <Skeleton
                    sx={Style.inputStyle}
                    variant="rectangular"
                    height={50}
                  />
                )}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      City
                      <span style={Style.star}>*</span>
                    </FormLabel>

                    <TextField
                      name="recipient_city"
                      value={formik.values.recipient_city}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter city"
                      sx={{
                        width: "100%",
                        border: "none",
                        // bgcolor: inputBgcolor,
                      }}
                      autoComplete="false"
                    />
                    {formik.errors.recipient_city &&
                    formik.touched.recipient_city ? (
                      <p style={{ color: "red" }}>
                        {formik.errors.recipient_city}
                      </p>
                    ) : null}
                  </Box>
                )}

                {loading && (
                  <Skeleton
                    sx={Style.inputStyle}
                    variant="rectangular"
                    height={50}
                  />
                )}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <InputLabel
                      // helperText={errors?.recipient_country?.message}
                      // errors={!!errors.recipient_country}
                      id="demo-simple-select-standard-label"
                      placeholder="Select country"
                      sx={{
                        fontSize: "24px",
                        color: "#333333",
                      }}
                    >
                      Country
                      <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      sx={{
                        width: "100%",
                        height: "53px",
                        ".MuiOutlinedInput-notchedOutline": {
                          border: "none",
                          bgcolor: "rgba(0, 0, 0, 0.06)",
                        },
                      }}
                      MenuProps={{ disableScrollLock: true }}
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      // defaultValue="select"
                      value={country}
                      onChange={handleChange}
                      label="Country"
                    >
                      {/* {country_names &&editAddressId&&
                        country_names.map((item, index) => (
                          <MenuItem value={formik.values.recipient_country}>{formik.values.recipient_country}</MenuItem>
                        ))} */}
                      <MenuItem value={country}>{country}</MenuItem>

                      {country_names &&
                        country_names.map((item, index) => (
                          <MenuItem key={index} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                    </Select>
                    {formik.errors.recipient_country &&
                    formik.touched.recipient_country ? (
                      <p style={{ color: "red" }}>
                        {formik.errors.recipient_country}
                      </p>
                    ) : null}
                    {/* <FormHelperText> {tst} </FormHelperText> */}
                  </Box>
                )}
              </Box>
              <Box sx={{ width: "100%" }}>
                {loading && (
                  <Skeleton
                    sx={Style.inputStyle}
                    variant="rectangular"
                    height={50}
                  />
                )}
                {!loading && (
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Postcode
                      <span style={Style.star}>*</span>
                    </FormLabel>

                    <TextField
                      name="recipient_postcode"
                      value={formik.values.recipient_postcode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter postcode"
                      sx={{
                        width: "100%",
                        border: "none",
                        // bgcolor: inputBgcolor,
                      }}
                      autoComplete="false"
                    />
                    {formik.errors.recipient_postcode &&
                    formik.touched.recipient_postcode ? (
                      <p style={{ color: "red" }}>
                        {formik.errors.recipient_postcode}
                      </p>
                    ) : null}
                  </Box>
                )}
              </Box>
            </>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "35%", lg: "40%" },
              display: "flex",
              justifyContent: {xs: "space-between",md:"flex-start"},
              pt: 4
            }}
          >
            <Button
              disableRipple
              sx={{
                mr: {md:3},
                pl: "25px", pr: "25px", pt:"10px", pb: "10px",
                fontSize: "18px",
                lineHeight: "21px",
                fontWeight: 400,
                borderRadius: "5px",
                textTransform: "none",
                border: "1px solid #EB5757",
                bgcolor: "#EB5757",
                color: "white",
                "&.MuiButtonBase-root:hover": {
                  border: "1px solid #EB5757",
                  bgcolor: "#EB5757",
                  color: "white",
                },
              }}
              variant="outlined"
              className="btn"
              onClick={()=>{
                navigate("/Users/address",{state:user_data})

              }}
            >
              Cancel
            </Button>
            <LoaderComponent open={loading}/>
            <Button
              disableRipple
              sx={{
                pl: "25px", pr: "25px", pt:"10px", pb: "10px",
                fontSize: "18px",
                lineHeight: "21px",
                fontWeight: 400,
                borderRadius: "5px",
                textTransform: "none",
                color: "white",
                bgcolor: "#27AE60",
                border: "1px solid #27AE60",
                "&.MuiButtonBase-root:hover": {
                  border: "1px solid #27AE60",
                  color: "white",
                  bgcolor: "#27AE60",
                },
              }}
              variant="outlined"
              className="btn"
              type="submit"
            >
              Save
            </Button>
          </Box>
        </form>
        <Toastify />
      </Box>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    // check_recipent: (item) => dispatch(check_recipent(item)),
    country_dropdown_data: () => dispatch(country_dropdown_data()),
    SaveAddressList: (data) => dispatch(SaveAddressList(data)),
    getUserAddress: (api_data) => dispatch(getUserAddress(api_data)),
  };
}
export default connect(null, mapDispatchToProps)(AddnewAddress);
const Style = {
  typographyStyle: {
    fontSize: { xs: "24px", md: "35px" },
    fontWeight: { xs: "400", md: "700" },
    lineHeight: { xs: "29px", md: "42px" },
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    display: "flex",
    pb: 3,
  },
  rowBoxStyle: {
    width: { xl: "100%", lg: "100%", sm: "100%", xs: "100%" },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  inputStyle: {
    width: {
      xs: "100%",
      sm: "100%",
      md: "45%",
      lg: "45%",
      xl: "45%",
    },
    mb: 2,
  },
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    // lineHeight: "24px",
    color: "#333333",
  },
  star: {
    color: "red",
  },
};
