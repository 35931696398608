import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Checkbox,
  Pagination,
  Typography,
} from "@mui/material";
import {
  EnhancedTableToolbar,
  EnhancedTableHead,
} from "../../components/Custom/Table/TableHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Toastify from "../../components/SnackBar/Toastify";
import {
  deleteAddress,
  getAddresses,
  // getUserAddress,
} from "../../redux/action/users";
import AlertDialog from "../../components/Custom/DialogBox/Dialog";
import { storage } from "../../config/storage";
import TopBox from "../../components/Custom/Boxes/TopBox";
import LoaderComponent from "../../components/Loader/LoaderComponent";
const Address = ({ getAddresses, deleteAddress }) => {
  const [deleteAddressId, setdeleteAddressId] = React.useState();
  const [popup, setPopup] = useState(false);
  const [value, setValue] = React.useState(false);
  const [addressData, setAddressData] = useState([]);
  const location = useLocation();
  const [model, setModal] = React.useState(false);

  const [loading, setLoading] = useState(true);
  const [deleteData, setDeleteData] = useState();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("price");
  const [selected, setSelected] = React.useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [search_val, setSearch_val] = React.useState("");
  const [Empty, setEmpty] = useState(false);
  const navigate = useNavigate();
  let length = 6;
  let data = {
    start: start,
    length: length,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getuserData();
  }, []);
  storage.set.user(location.state);
  let user_data = location.state;

  const getuserData = () => {
    setLoading(true);
    let id = { users_id: user_data.users_id };
    Object.assign(data, id);
    getAddresses(data).then((res) => {
      setLoading(false);
      if (res?.data?.data?.total_records == 0) {
        setRows(res.data.data.user_addresses);
        setEmpty(true);
      }
      if (res.data.status) {
        setEmpty(false);
        setRows(res.data.data.user_addresses);
        setPages(res.data.data.pages);
      }
    });
  };
  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }

    getuserData();
    setSelected([])
  };
  const onSubmit = () => {
    setStart(0);
    setPage(1);
    data = {
      start: 0,
      length: length,
    };
    let searchVal = { search_val: search_val };
    Object.assign(data, searchVal);
    if (order && orderBy) {
      let sort_column = { sort_column: orderBy };
      let sort = { sort_by: order };
      Object.assign(data, sort_column, sort);
    }
    getuserData();
    setSelected([])
  };

  const handleRequestSort = (event, property) => {
    let sort, sort_column;
    let isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder("desc");
      sort = { sort_by: "asc" };
      sort_column = { sort_column: property };
      setOrderBy(property);
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      sort_column = { sort_column: property };
      sort = { sort_by: order };
    }
    Object.assign(data, sort_column, sort);
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }
    getuserData();
    setSelected([])
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((user) => user.address_id);
      setSelected(newSelected);

      const arr1 = newSelected.map((userId) => {
        return { id: userId };
      });
      setDeleteData(arr1);
    } else setSelected([]);
  };
  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    } else {
    }

    setSelected(newSelected);
    const arr1 = newSelected.map((userId) => {
      return { id: userId };
    });
    setDeleteData(arr1);
  };
  const deleteUser = () => {
    let data = { addresses: deleteData };
    deleteAddress(data).then((res) => {
      if (res?.data?.status) {
        getuserData();
        setModal(false);
        setSelected([]);
        setDeleteData([]);
        toast.success("Deleted Successfully!!")
      } else {
        setLoading(true);
        setSelected([]);
        setDeleteData([])
        setModal(false)
        toast.error(res.data.message);
      }
    });
  };

  const isSelected = (title) => selected.indexOf(title) !== -1;
  const handleEdit = (id) => {
    navigate("/Users/address/addnew", { state: id });
  };
  return (
    <Box>
      <TopBox
        headerText={`${user_data.first_name} Address`}
        button_one={"Search"}
        button_two={"Delete"}
        button_three={"Add new"}
        onClick="/Users/address/addnew"
        deleteAPI={() => {
          if (deleteData !== undefined || selected === []) {
            setModal(true);
          }
        }}
        onSubmit={onSubmit}
        search_val={search_val}
        setSearch_val={setSearch_val}
        numSelected={selected.length}
      />
      {Empty && (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 20,
            pb: 20,
            fontSize: {xs:"24px",md:"35px"},
            color: "#A8A8A8",
            fontWeight: "700",
          }}
        >
          No Address Found
        </Typography>
      )}
      {!Empty && (
        <Box sx={{ width: "100%" ,overflow:'auto'}}>
        <Table>
          <LoaderComponent open={loading} />

          <EnhancedTableHead
            totalColumn={[
              "Name",
              "Address",
              "City",
              "Country",
              "Post Code",
              "Action",
            ]}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <AlertDialog
            title={"You are about to delete Address category"}
            text={"This will delete the Address from the list"}
            onClickButton={() => deleteUser()}
            onClickButtonCancel={() => {
              setModal(false)

            }}
            open={model}
          />
          <TableBody
            sx={{
              minHeight: 500,
            }}
          >
            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.address_id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.address_id}
                >
                  <TableCell>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && (
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                        onClick={(event) => handleClick(event, row.address_id)}
                        selected={isItemSelected}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                    sx={Style.tableCell}
                    style={{
                      paddingLeft: 20
                    }}
                  >
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && row.recipient_name}
                  </TableCell>
                  <TableCell sx={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && row.recipient_address1}
                  </TableCell>
                  <TableCell sx={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && row.recipient_city}
                  </TableCell>
                  <TableCell sx={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && row.recipient_country}
                  </TableCell>
                  <TableCell sx={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && row.recipient_postcode}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      minHeight: 100,
                    }}
                  >
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && (
                      <>
                        <img
                          src={require("../../assets/edit.png")}
                          style={{
                            width: "18px",
                            height: "18px",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleEdit(row.address_id);
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          p: 3,
        }}
      >
        {pages > 1 && (
          <Pagination
            count={pages}
            page={page}
            boundaryCount={1}
            onChange={handlePageChange}
            siblingCount={0}
            sx={{button:{fontSize:'16px'}}}

          />
        )}
      </Box>
      <Toastify/>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    deleteAddress: (data) => dispatch(deleteAddress(data)),
    getAddresses: (id) => dispatch(getAddresses(id)),
  };
}
export default connect(null, mapDispatchToProps)(Address);
const Style = {
  tableCell: {
    minWidth: "125px",
    maxWidth: "125px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
};
