import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Skeleton } from "@mui/material";
import { useLocation } from "react-router-dom";
import React from "react";
import LoaderComponent from "../../../components/Loader/LoaderComponent";
import "../../../App.css";

const CardView = ({
  load,
  title,
  price,
  description,
  isActive,
  mainImg,
  pcImg,
  moreImg,
}) => {
  const location = useLocation();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: { xs: "column", lg: "row" },
          justifyContent: "space-around",
        }}
      >
        <LoaderComponent open={load} />
          <Box sx={Style.box1}>
            <Box sx={Style.box2}>
              {load && <Skeleton variant="rectangular" height={350} width={270} /> }
              {!load &&
              <img
                style={{ width: "100%", height: "350px",borderRadius:"5px" }}
                alt="img"
                src={mainImg}
              />}
              <Typography style={Style.typography4}>Main Image</Typography>
            </Box>
            <Box sx={Style.box2}>
            {load && <Skeleton variant="rectangular" height={350} width={270} /> }
            {!load && !pcImg &&
              <Typography
                sx={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  height: "350px",
                  fontSize:'18px',
                  color:'#A8A8A8',
                  bgcolor: "grey.200",
                  borderRadius: "5px",
                }}
              > No Personalised Image </Typography>
            }
              {!load && pcImg &&
              <img
                style={{ width: "100%", height: "350px" }}
                alt="img"
                src={pcImg}
              />}
              <Typography style={Style.typography4}>
                Personalised cover Image
              </Typography>
            </Box>
          </Box>
       
        
          <Box
            sx={{
              width: "70%",
              p: "20px",
              pl: {xs:0, lg: "20px"},
              display: "flex",
              flexDirection: "column",
            }}
          > 
           {load && <Skeleton variant="rectangular" sx={{height: 40, width: "100%", mb:2 }} />}
           {!load && <Typography component={"span"} sx={Style.typography1}>
              {title}
            </Typography> }
            {load && <Skeleton variant="rectangular" sx={{height: 40, width: "100%", mb:2 }} />}
            {!load && <Typography component={"span"} sx={Style.typography1}>
              ${price}
            </Typography>}
            {load && <Skeleton variant="rectangular" sx={{height: 140, width: "100%", mb:2 }} />}
            {!load && <>
            <Typography component={"span"} sx={Style.typography2}>
              Description
            </Typography>
            <Typography component={"span"} sx={Style.typography3}>
              {description}
            </Typography></>}
          </Box>
        
      </Box>
      {location.pathname === "/greeting/cardlisting/viewcardlisting" && (
        <>
          <Typography style={Style.typography1} sx={{ mt: 7 }}>
            More images :
          </Typography>

          <Stack direction={{ xs: "column", sm: "row" }} sx={Style.stack1}>
            {load && 
            [1, 2, 3, 4, 5, 6, 7, 8].map((item,index) => (
              <Box sx={Style.itemBox} key={index}>
                <Skeleton
                  variant="rectangular"
                  sx={{ borderRadius: "10px", pl: "25px" }}
                  width={'100%'}
                  height={"270px"}
                />
              </Box>
            ))}
            {!load && moreImg.length === 0 && 
            <Box sx={{display: "flex", width: "100%" ,justifyContent: "center"}}>
            <Typography sx={{
              pt:5,
              fontSize:'35px',
              color:'#A8A8A8',
              fontWeight:'700',
            }}>
              No More Images Found
            </Typography></Box>}
            {!load &&
              moreImg &&
              moreImg?.map((card, index) => (
                <Box sx={Style.itemBox}>
                  <Box sx={Style.item1} key={index}>
                    <img
                      alt="logo"
                      // className="cardDetailPagePics"
                      // className="listCardImage"
                      style={{ height: "250px", width: "100%" }}
                      src={card.more_file_path}
                    />
                  </Box>
                </Box>
              ))}
          </Stack>
        </>
      )}
    </Box>
  );
};

export default CardView;

const Style = {
  typography1: {
    fontSize: "24px",
    fontWeight: 700,
    mb: 2,
  },
  typography2: {
    fontSize: "20px",
    fontWeight: 400,
    mb: 1,
  },
  typography3: {
    fontSize: "18px",
    fontWeight: 400,
  },
  typography4: {
    fontSize: "16px",
    fontWeight: 400,
    textAlign: "center",
    mb: 3,
    minHeight: "50px"
  },
  box1: {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    width: {sm:"99%",md:"99%",lg:"70%"},
    justifyContent: "space-between",
    // bgcolor: "yellow",
  },
  box2: {
    width: { sm: "48%",md: "48%" },
    display: "flex",
    flexDirection: "column",
  },
  stack1: {
    display: "flex",
    flexWrap: "wrap",
    // height: "100%",
    ml: { xs: 0, sm: -1, md: -2, lg: -3, xl: -3 },
    mr: { xs: 0, sm: -1, md: -2, lg: -4, xl: -4 },
    // bgcolor:"red",
  },
  itemBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    mt: { xs: 5, sm: 5, md: 8, lg: 5, xl: 5 },
    pl: { xs: "0%", sm: "1%", md: "1.5%", lg: "2%", xl: "2%" },
    pr: { xs: "0%", sm: "1%", md: "1.5%", lg: "2%", xl: "2%" },
    maxWidth: { xs: "100%", sm: "49%", md: "32.5%", lg: "25.0%", xl: "19.5%" },
    width: { xs: "100%", sm: "49%", md: "32.5%", lg: "25.0%", xl: "19.5%" },
    // bgcolor:"yellow"
  },
  item1: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: { xs: "10px", sm: "5px" },
    pl: 1,
    pr: 1,
    pt: 1,
    border: "1px solid rgba(61, 46, 87, 0.4)",
    pb: 1,
    cursor: "pointer",
    bgcolor: "#FFFFFF",
    // bgcolor:"red"
  },
  item2: {
    maxWidth: { xs: "99%", sm: "350px", md: "380px", lg: "416px" },
    width: "100%",
    maxHeight: { xs: "450px", sm: "350px", md: "380px", lg: "418px" },
    borderRadius: { xs: "20px", sm: "10px" },
    ml: { xs: 0, sm: 1, md: 0 },
    border: "1px solid rgba(61, 46, 87, 0.4)",
    mr: { xs: 0, sm: 0, md: 0 },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    bgcolor: "#FFFFFF",
    mt: 2,
  },
};
