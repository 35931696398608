import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Link, Outlet, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: "#3D2E57",
    fontSize: "18px",
    fontWeight: "400",
    "&.MuiTab-root.Mui-selected": {
      backgroundColor: "#3D2E57",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.125rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.65rem",
        width:"50%"
      },
      color: "white",
      textTransform: "none",
      borderRadius: "5px",
    },
    "&.MuiButtonBase-root": {
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.125rem",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "0.65rem",
        width:"50%"
      },
      backgroundColor: "rgba(42, 34, 70, 0.1)",
      color: "#3D2E57",
      textTransform: "none",
      borderRadius: "5px",
    },
  },
}));
export default function GreetingCard() {
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  const route = ["/e-giftcards/list", "/e-giftcards/amount"];
  const location = useLocation();
  const handleChange = (event, newValue) => {};
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Effra",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "29px",
          color: "#3D2E57",
          pb: 2,
        }}
      >
        E-Gift Card
      </Typography>
      <Box sx={{ borderColor: "divider" }}>
        <Tabs
          value={location.pathname}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none",
          }}
        >
          <Tab
            classes={tabClasses}
            label="E-Gift Card List"
            value={route[0]}
            LinkComponent={Link}
            to={route[0]}
          />
          <Tab
            classes={tabClasses}
            label="E-Gift Card Amount"
            value={route[1]}
            LinkComponent={Link}
            to={route[1]}
          />
        </Tabs>
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  );
}
