import { toast } from "react-toastify";
import GiftApi from "../../services/giftCards";

export const giftamountlist = (data) => async (dispatch) => {
  try {
    let response = await GiftApi.requestGiftAmountList(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
}

export const addgiftamount = (data) => async (dispatch) => {
  try {
    let response = await GiftApi.requestAddgiftamount(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
}
export const deletegiftamount = (data) => async (dispatch) => {
    try {
      let response = await GiftApi.requestDeletegiftamount(data);
  
      if (response.status) {
        // dispatch(OrdersData(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {}
  }
  export const getAmount = (data) => async (dispatch) => {
    try {
      let response = await GiftApi.requestgetamount(data);
  
      if (response.status) {
        // dispatch(OrdersData(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {}
  }