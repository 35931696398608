import React from "react";
import { Routes, Route } from "react-router-dom";
import Configuration from "../pages/Configuration/Configuration";
import Login from "../pages/Login/Login";
import Profile from "../pages/MyProfile/Profile";
import Greetingcards from "../pages/greetingCards/Greetingcards";
import EgiftCards from "../pages/e-giftCards/EgiftCards";
import GiftCards from "../pages/giftCards/GiftCards";
import { Box } from "@mui/material";
import Users from "../pages/Users/Users";
import AddNewUser from "../pages/Users/Addnewuser"
import CardView from "../components/Custom/ViewCardDetail/CardView";
import Orders from "../pages/Orders/Orders";
import ProtectedRoute from "../routes/ProtectedRoutes"
import Reminder from "../pages/Users/Reminder";
import AddNewCardListing from "../pages/greetingCards/AddNewCardListing";
import AddNewEgift from "../pages/e-giftCards/AddNewEgift";
import ViewEgiftCards from "../pages/e-giftCards/ViewEgiftCards";
import OrderDetail from "../pages/Orders/OrderDetail";
import ItemDetail from "../pages/Orders/ItemDetail";
import Address from "../pages/Users/Address";
import AddnewAddress from "../pages/Users/AddnewAddress";
import AddNewCategory from "../pages/greetingCards/AddNewCategory";
import ViewCardListing from "../pages/greetingCards/ViewCardListing";
import GreetingCardCategories from "../pages/greetingCards/GreetingCardCategories";
import GreetingCardListing from "../pages/greetingCards/GreetingCardListing";
import OrderPage from "../pages/Orders/OrderPage";
import OrderAccepted from "../pages/Orders/OrderAccepted";
import OrderDeclined from "../pages/Orders/OrderDeclined";
import OrderPending from "../pages/Orders/OrderPending";
import EgiftCardList from "../pages/e-giftCards/EgiftCardList";
import EgiftCardAmount from "../pages/e-giftCards/EgiftCardAmount";
import AddAmount from "../pages/e-giftCards/AddAmount";
import AddGiftCard from "../pages/giftCards/AddGiftCard";
// import HomePage from "../home/homePage";
const Index = () => {
  return (
    <>
      <Box sx={{ width: "100%", bgcolor: 'white', }}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/greeting/cardview" element={<CardView />} />
          <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
          <Route exact path="/configurations" element={<ProtectedRoute><Configuration /></ProtectedRoute>} />
          <Route exact path="/GreetingCardListing" element={<ProtectedRoute> <GreetingCardListing /> </ProtectedRoute>} />
          <Route exact path="/greeting/categories/addnew" element={<ProtectedRoute> <AddNewCategory /> </ProtectedRoute>} />
          <Route exact path="/greeting/categories/editcategory" element={<ProtectedRoute> <AddNewCategory /> </ProtectedRoute>} />
          <Route exact path="/greeting/cardlisting/addnew" element={<ProtectedRoute> <AddNewCardListing /> </ProtectedRoute>} />
          <Route exact path="/greeting/cardlisting/editcardlist" element={<ProtectedRoute> <AddNewCardListing /> </ProtectedRoute>} />
          <Route exact path="/greeting/cardlisting/viewcardlisting" element={<ProtectedRoute> <ViewCardListing /> </ProtectedRoute>} />
          <Route path="/greeting" element={<ProtectedRoute>  <Greetingcards /></ProtectedRoute>}  >
            <Route
              path="/greeting/categories"
              element={<GreetingCardCategories />}
            />
            <Route
              path="/greeting/cardlisting"
              element={<GreetingCardListing />}
            />
          </Route>
          <Route path="/e-giftcards" element={<ProtectedRoute><EgiftCards /></ProtectedRoute>} >
          <Route
              path="/e-giftcards/list"
              element={<EgiftCardList />}
            />
            <Route
              path="/e-giftcards/amount"
              element={<EgiftCardAmount />}
            />
          </Route>
          <Route
              path="/e-giftcards/amount/update"
              element={<AddAmount />}
            />
          <Route path="/e-giftcards/edite-giftcards" element={<ProtectedRoute><AddNewEgift /></ProtectedRoute>} />
          <Route path="/e-giftcards/addnewe-giftcards" element={<ProtectedRoute><AddNewEgift /></ProtectedRoute>} />
          <Route path="/e-giftcards/viewe-giftcards" element={<ProtectedRoute><ViewEgiftCards /></ProtectedRoute>} />
          <Route path="/giftcards" element={<ProtectedRoute><GiftCards /></ProtectedRoute>} />
          <Route path="/giftcards/update" element={<ProtectedRoute><AddGiftCard /></ProtectedRoute>} />
          <Route path="/Users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
          <Route path="/Users/addnewuser" element={<ProtectedRoute><AddNewUser /></ProtectedRoute>} />
          <Route path="/Users/reminder" element={<ProtectedRoute><Reminder /></ProtectedRoute>} />
          <Route path="/Users/address" element={<ProtectedRoute><Address /></ProtectedRoute>} />
          <Route path="/Users/address/addnew" element={<ProtectedRoute><AddnewAddress /></ProtectedRoute>} />
          {/* <Route path="/orders" element={<OrderPage />} /> */}
          <Route path="/orders/orderdetail" element={<ProtectedRoute><OrderDetail /></ProtectedRoute>} />
          <Route path="/orders/itemdetail" element={<ProtectedRoute><ItemDetail /></ProtectedRoute>} />
          <Route path="/orders" element={<ProtectedRoute>  <OrderPage /></ProtectedRoute>}  >
            <Route
              path="/orders/list"
              element={<Orders />}
            />
            <Route
              path="/orders/declined"
              element={<OrderDeclined />}
            />
            <Route
              path="/orders/approved"
              element={<OrderAccepted />}
            />
             <Route
              path="/orders/pending"
              element={<OrderPending />}
            />
          </Route>
        </Routes>
      </Box>
    </>
  );
};

export default Index;
