import { Api } from "../../../config/request";

export const requestGreetingCategoryData = async (data) => {
  return Api.PostRequest("/admin/categories/list", data);
};
export const requestgetSpecificCategoryData = async (data) => {
  return Api.PostRequest("/admin/get/category", data);
};
export const requestEdit_Add_CategoryData = async (data) => {
  return Api.PostRequest("/admin/save/category", data);
};
export const requestRemoveCategoryData = async (data) => {
  return Api.PostRequest("/admin/remove/categories", data);
};
export const requestgetGreetingCardListing = async (data) => {
  return Api.PostRequest("/admin/greeting/cards/list", data);
};
export const requestDeleteCard = async (data) => {
  return Api.PostRequest("/admin/remove/greeting/cards", data);
};
export const requestgetEditViewCardList = async (data) => {
  return Api.PostRequest("/admin/get/greeting/card", data);
};
export const requestSaveAddEditCardList = async (data) => {
  return Api.PostRequest("/admin/save/greeting/card", data);
};
export const requestDeleteMoreImages = async (data) => {
  return Api.PostRequest("/admin/remove/more/greeting/cards", data);
}
export const requestGreetingCategoryDataList = async (data) => {
  return Api.PostRequest("/admin/get/all/categories", data);
};
const GreetingApi = {
  requestGreetingCategoryData,
  requestgetSpecificCategoryData,
  requestEdit_Add_CategoryData,
  requestRemoveCategoryData,
  requestgetEditViewCardList,
  requestSaveAddEditCardList,
  requestgetGreetingCardListing,
  requestDeleteCard,
  requestDeleteMoreImages,
  requestGreetingCategoryDataList
};
export default GreetingApi;
