import {
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormLabel,
  TextField,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Skeleton,
  Chip,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState, useEffect } from "react";
import { Controller } from "react-hook-form";
import { useFormik } from "formik";
import * as yup from "yup";
import { connect, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { array } from "prop-types";
import { $ } from "glamor";
import { EnhancedTableHead } from "../../components/Custom/Table/TableHeader";
import {
  getEditViewCardList,
  SaveAddEditCardList,
  DeleteMoreImages,
  getGreetingCategoriesList
} from "../../redux/action/greetingCards";
import { useLocation, useNavigate } from "react-router-dom";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import Toastify from "../../components/SnackBar/Toastify";
import { toast } from "react-toastify";
const schema = yup.object().shape({
  title: yup.string().required("Please enter Title"),
  price: yup.string().required("Please enter price").matches(/^[0-9,<.>]*$/,"Please enter valid Price"),
  description: yup.string().required("Please enter Description"),
  category_name:yup.string()
});
const AddNewCardListing = ({
  getEditViewCardList,
  DeleteMoreImages,
  SaveAddEditCardList,
  getGreetingCategoriesList
}) => {
  const [load, setLoad] = useState(false);
  const [card, setCard] = useState("select");
  const [categoryDropDown, setcategoryDropDown] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [mainImg, setMainImg] = useState({ id: "", path: "",src: "" }); // src -> for preview image
  const [pcImg, setPcImg] = useState({ id: "", path: "",src: "" });
  const [moreImg, setMoreImg] = useState("");
  const [alignment, setAlignment] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [Imgerr, setImgerr] = useState(false);
  // const newState = useSelector((state) => state?.GreetingCards?.cardlisting);
  const [imagesArray, setImagesArray] = useState([]);
  const [sendImages, setSendImages] = useState([]);
  const [userData, setUserData] = useState({
    title: "",
    price: "",
    description: "",
    category_name:card,
  });

  const formik = useFormik({
    initialValues: userData,
    validationSchema: () => {
      if(mainImg.path === ""){
        setImgerr(true);
      }
      return schema;
    },
    onSubmit: (value) => {
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  const onSubmit = async (model) => {
    let item = model;
    delete item.category_name;
    delete item.active_formatted;
    delete item.created_at;
    delete item.created_at_formatted;
    delete item.main_file;
    delete item.more_files;
    delete item.pc_file;
    delete item.user_type;
    item.is_active = alignment === "Yes" ? 1 : 0;
    item.category_id = categoryId;
    if (mainImg.path.name1) {  // name1-> if path coming from backend at time of edit, if simply coming name then this is choosen path by own
      item.main_image_id = "";
      item.main_image = "";
    } else {
      item.main_image_id = mainImg.id; // this is own choose file of id and path set in mainImg
      item.main_image = mainImg.path;
    }
    if (pcImg.path.name1) {
      item.pc_image_id = "";
      item.pc_image = "";
    } else {
      item.pc_image_id = pcImg.id;
      item.pc_image = pcImg.path;
    }
    for (var a = 0; a < sendImages.length; a++) {
      Object.assign(item, { [`more_images_id[${a}]`]: sendImages[a].id });
    }
    Object.assign(item, {
      [`more_images[]`]: sendImages.map((row, index) => row.path),
    });
    if(!Imgerr && card!=="select"){
      setLoad(true);
      SaveAddEditCardList(item).then((res) => {
      setLoad(false);
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        setTimeout(() => {
          navigate("/greeting/cardlisting");
        }, 1000);
      }
    });
  }
  };
  const handleChange = (e) => {
    setCard(e.target.value);
  };
  const handleIsActive = (e, newAlignment) => {
    setAlignment(newAlignment);
  };
  const handleMainImg = (e) => {
    setImgerr(false)
    setMainImg({ id: mainImg.id, path: e.target.files[0], src: URL.createObjectURL(e.target.files[0]) });
  };

  const handlePersonliseImg = (e) => {
    setPcImg({ id: pcImg.id, path: e.target.files[0], src: URL.createObjectURL(e.target.files[0]) });
  };
  const handleMoreImg = (e) => {
    setMoreImg(e.target.files[0]);
  };
  const handleEdit = (e, index, id) => {
    if (e.target.files[0]) {
      imagesArray.map((row, ind) => {
        if (index === ind) {
          row.path = e.target.files[0].name;
          row.src = URL.createObjectURL(e.target.files[0]);
        }
        return imagesArray;
      });
      setImagesArray([...imagesArray]);
      sendImages.push({ id: id, path: e.target.files[0] });
      setSendImages([...sendImages]);
    }
  };
  const handleSubmitImages = (id) => {
    if (moreImg !== "") {
      imagesArray.push({
        id: id,
        path: moreImg.name,
        src: URL.createObjectURL(moreImg),
      });
      sendImages.push({ id: id, path: moreImg });
      toast.success("Image Added");
      setImagesArray([...imagesArray]);
      setSendImages([...sendImages]);
      document.querySelector(".file").value = "";
      setMoreImg("");
    }
  };
  const handleRemoveImages = (path) => {
    imagesArray.map((row, index) => {
      if (path === row.path) {
        imagesArray.splice(index, 1);
      }
      return imagesArray;
    });
    sendImages.map((row, index) => {
      if (path === row.path.name && row.id === "") {
        sendImages.splice(index, 1);
      }
      return sendImages;
    });
    toast.success("Image Removed");
    setImagesArray([...imagesArray]);
    setSendImages([...sendImages]);
  };
  const DeleteMore_Images = (id,index) => {
    let data = {more_image_id : id};
    setLoad(true);
    DeleteMoreImages(data).then((res) => {
      setLoad(false);
      if(res?.data?.status){
        if(res?.data?.status){
          imagesArray.splice(index,1);
          sendImages.map((row,ind) => {
            if(row.id === id){
              sendImages.splice(ind,1);
            }
            return sendImages;
          })
          toast.success(res?.data?.message)
          setImagesArray([...imagesArray]);
          setSendImages([...sendImages]);
        }
      }
      else{
        toast.success(res?.data?.message)
      }
    })
  }

  const getCategoriesList = () => {
    getGreetingCategoriesList().then((res) => {
      // setCard(res?.data?.data?.result?.category_name)
      setcategoryDropDown(res?.data?.data?.result);
    });
  };
  const EditViewCardList = () => {
    let data = { card_id: location.state };
    setLoad(true);
    getEditViewCardList(data).then((res) => {
      setLoad(false);
      if (res?.data?.status) {
        const result = res?.data?.data;
        setUserData(result);
        setAlignment(result?.is_active === "1" ? "Yes" : "No");
        setCard(result?.category_name);
        setCategoryId(result?.category_id);
        const main_file = result?.main_file;
        setMainImg({
          id: main_file?.main_file_id,
          path: {
            name1: main_file?.main_file_path?.substring(
              main_file?.main_file_path.lastIndexOf("/") + 1
            ),
          },
          src: main_file?.main_file_path,
        });
        setImgerr(false)
        const pc_file = result?.pc_file;
        setPcImg({
          // id: pc_file?.pc_file_id,
          id: pc_file.length === 0 ? "" : pc_file.pc_file_id,
          path: {
            name1: pc_file?.pc_file_path?.substring(
              pc_file?.pc_file_path.lastIndexOf("/") + 1
            ),
          },
          src: pc_file?.pc_file_path,
        });
        
        const more_file = result?.more_files;
        setImagesArray(
          more_file.map((row, index) => {
            return {
              id: row.more_file_id,
              path: row?.more_file_path?.substring(
                row?.more_file_path.lastIndexOf("/") + 1
              ),
              src: row?.more_file_path,
            };
          })
        );
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getCategoriesList();
    if (location.state) {
      EditViewCardList();
    }
  }, []);

  return (
    <form
      style={{ minHeight: "600px" }}
      name="RegisterForm"
      onSubmit={formik.handleSubmit}
    >
      <Box
        sx={{
          width: "100%",
          height: "30%",
          borderRadius: 2,
        }}
      >
        <LoaderComponent open={load} />
        <Typography
          sx={{
            fontFamily: "Effra",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "29px",
            pl: 2,
            pb: 2,
            color: "#000000",
          }}
        >
          {location?.state ? "Edit" : "Add"} Greeting Card List
        </Typography>
        <Box sx={Style.rowBoxStyle}>
          {load && (
            <Skeleton
              sx={Style.inputStyle}
              style={{ marginBottom: "48px", marginTop: "16px" }}
              variant="rectangular"
              height={60}
            />
          )}
          {!load && (
            <Box sx={Style.inputStyle}>
              <FormLabel sx={Style.label}>
                Title
                <span style={Style.star}>*</span>
              </FormLabel>

              <TextField
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                variant="filled"
                InputProps={{ disableUnderline: true, pt: "10px" }}
                inputProps={{
                  style: {
                    paddingTop: "16px",
                    paddingBottom: "15px",
                  },
                }}
                color="primary"
                placeholder="Enter Title"
                sx={{
                  width: "100%",
                  border: "none",
                }}
                autoComplete="false"
              />
              {formik.errors.title && formik.touched.title ? (
                <p style={{ color: "red" }}>{formik.errors.title}</p>
              ) : null}
            </Box>
          )}
          {load && (
            <Skeleton
              sx={Style.inputStyle}
              style={{ marginBottom: "48px", marginTop: "16px" }}
              variant="rectangular"
              height={60}
            />
          )}
          {!load && (
             <Box sx={Style.inputStyle}>
             <InputLabel
               id="demo-simple-select-standard-label"
               placeholder="Select country"
               sx={{
                 fontSize: "20px",
                 color: "#333333",
               }}
             >
               Category
               <span style={Style.star}>*</span>
             </InputLabel>
             <Select
               sx={{
                 width: "100%",
                 height: "54px",
                 ".MuiOutlinedInput-notchedOutline": {
                   border: "none",
                   bgcolor: "rgba(0, 0, 0, 0.06)",
                 },
               }}
               MenuProps={{ disableScrollLock: true }}
               labelId="demo-simple-select-standard-label"
               id="demo-simple-select-standard"
               // defaultValue="select"s
               value={card}
               onChange={handleChange}
               label="select"
             >
               <MenuItem value="select">Select</MenuItem>
               
               {categoryDropDown&& categoryDropDown.map(
                 (item, index) =>(
                     <MenuItem
                       key={index}
                       value={item.category_name}
                       onClick={() => {setCategoryId(item.categories_id)
                      setCard(item.category_name)}}
                     >
                       {item.category_name}
                     </MenuItem>
               ))}
               </Select>
               {card==="select" && formik.touched.category_name&&
                       (
                         <p style={{ color: "red" }}>
                           Please Select Category
                         </p>
                       ) }
           </Box>
          )}
        </Box>
        <Box sx={Style.rowBoxStyle}>
          {load && (
            <Skeleton
              sx={Style.inputStyle}
              style={{ marginBottom: "48px" }}
              variant="rectangular"
              height={60}
            />
          )}
          {!load && (
            <Box sx={Style.inputStyle}>
              <FormLabel sx={Style.label}>
                Enter price
                <span style={Style.star}>*</span>
              </FormLabel>

              <TextField
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                variant="filled"
                InputProps={{ disableUnderline: true, pt: "10px" }}
                inputProps={{
                  style: {
                    paddingTop: "16px",
                    paddingBottom: "15px",
                  },
                }}
                color="primary"
                placeholder="$0.0"
                sx={{
                  width: "100%",
                  border: "none",
                }}
                autoComplete="false"
              />
              {formik.errors.price && formik.touched.price ? (
                <p style={{ color: "red" }}>{formik.errors.price}</p>
              ) : null}
            </Box>
          )}
          {load && (
            <Skeleton
              sx={Style.inputStyle}
              style={{ marginBottom: "48px" }}
              variant="rectangular"
              height={60}
            />
          )}
          {!load && (
            <Box sx={Style.inputStyle}>
              <FormLabel sx={Style.label}>
                Description
                <span style={Style.star}>*</span>
              </FormLabel>

              <TextField
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                variant="filled"
                InputProps={{ disableUnderline: true, pt: "10px" }}
                inputProps={{
                  style: {
                    paddingTop: "16px",
                    paddingBottom: "15px",
                  },
                }}
                color="primary"
                placeholder="Description"
                sx={{
                  width: "100%",
                  border: "none",
                }}
                autoComplete="false"
              />
              {formik.errors.description && formik.touched.description ? (
                <p style={{ color: "red" }}>{formik.errors.description}</p>
              ) : null}
            </Box>
          )}
        </Box>
        <Box>
          <Box sx={Style.rowBoxStyle}>
            {load && (
              <Skeleton
                sx={Style.inputStyle}
                style={{ marginBottom: "48px" }}
                variant="rectangular"
                height={60}
              />
            )}
            {!load && (
              <Box sx={Style.inputStyle}>
                <FormLabel sx={Style.label}>
                  Main image
                  <span style={Style.star}>*</span>
                </FormLabel>
                {!mainImg.src && (
                  <>
                  <Box sx={{ width: "100%", bgcolor: "#F6F6F6" }}>
                    <input
                      style={{ padding: "17px 12px", }}
                      type="file"
                      id="upload"
                      name="main_image"
                      accept="image/png, image/gif, image/jpeg"
                      //   onChange={(img) =>
                      //     handleMainImg(img)
                      // }
                      onChange={handleMainImg}
                    />
                    </Box>
                   
                  </>
                )}
                {mainImg.src && (
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: "#F6F6F6",
                      height: 60,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pl: 2,pr:1,
                    }}
                  >
                    <Chip
                      label={mainImg.path.name || mainImg.path.name1}
                      component="a"
                      // href={mainImg}
                      variant="outlined"
                      name="main_image"
                      // clickable
                      onDelete={() => {setMainImg({ id: mainImg.id, path: "" })
                        setImgerr(true)
                    }}
                    />
                    <img
                     alt="logo"
                     style={{height: 50, width: 40 }}
                     src={mainImg.src}
                     />
                      
                  </Box>
                  
                )}
                {Imgerr &&
                       (
                         <p style={{ color: "red" }}>
                           Please Choose Image
                         </p>
                       ) }
              </Box>
            )}
            {load && (
              <Skeleton
                sx={Style.inputStyle}
                style={{ marginBottom: "80px" }}
                variant="rectangular"
                height={60}
              />
            )}
            {!load && (
              <Box sx={Style.inputStyle}>
                <FormLabel sx={Style.label}>Personalised cover image</FormLabel>
                {!pcImg.src && (
                  <Box sx={{ width: "100%", bgcolor: "#F6F6F6" }}>
                    <input
                      style={{ padding: "17px 12px" }}
                      type="file"
                      id="upload"
                      // name="pcImg"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handlePersonliseImg}
                    />
                  </Box>
                )}
                {pcImg.src && (
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: "#F6F6F6",
                      height: 60,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      pl: 2,pr:1
                    }}
                  >
                    <Chip
                      label={pcImg.path.name || pcImg.path.name1}
                      component="a"
                      // href={mainImg}
                      variant="outlined"
                      // clickable
                      onDelete={() => setPcImg({ id: pcImg.id, path: "" })}
                    />
                    <img
                     alt="logo"
                     style={{height: 50, width: 40 }}
                     src={pcImg.src}
                     />
                  </Box>
                )}
                <p style={{ fontSize: "14px" }}>
                  *Upload image for personalise message
                </p>
              </Box>
            )}
          </Box>
          <Box sx={Style.rowBoxStyle}>
            {load && (
              <Skeleton
                sx={Style.inputStyle}
                // style={{ marginLeft: "24px" }}
                variant="rectangular"
                height={60}
              />
            )}
            {!load && (
              <Box sx={Style.inputStyle1}>
                <Box
                  sx={{ 
                    minWidth: {xs:"45%"},
                    width: {xs:"85%",sm:"85%",md:"75%",lg:"95%"},
                    mb: 2,
                    mr:2,
                    color: {xs:"transparent",sm:"inherit"}
                  }}
                >
                  <FormLabel sx={Style.label}>More Images</FormLabel>
                  <Box sx={{ bgcolor: "#F6F6F6" }}>
                    <input
                      style={{ padding: "17px 12px" ,overflow:"hidden"}}
                      type="file"
                      id="upload"
                      className="file"
                      name="moreImg"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={handleMoreImg}
                     
                    />
                  </Box>
                </Box>
                <Button
                  sx={{
                    bgcolor: "#3D2E57",
                    "&.MuiButtonBase-root:hover": {
                      bgcolor: "#3D2E57",
                    },
                    color: "white",
                    fontSize: 50,
                    maxHeight: 56,
                    mt: 3.5,
                    ml:{xs:0,md:0},
                    // width:{xs:"1%", sm: "10%",md:"10%"}
                  }}
                  component="span"
                  onClick={() => handleSubmitImages("")}
                >
                  +
                </Button>
              </Box>
            )}
            {load && (
              <Skeleton
                sx={Style.inputStyle}
                // style={{ marginLeft: "24px" }}
                variant="rectangular"
                height={60}
              />
            )}
            {!load && (
              <Box sx={Style.inputStyle}>
                <Typography sx={{ fontSize: "20px",fontWeight: 400, lineHeight: "24px",color: "#333333",mb:0.5 }}>Is Active</Typography>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleIsActive}
                  sx={{height: 56}}
                  aria-label="Is Active"
                >
                  <ToggleButton color="primary" value="Yes" sx={{width: "64px"}}>
                    Yes
                  </ToggleButton>
                  <ToggleButton color="standard" value="No" sx={{width: "64px"}}>
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {!load && imagesArray.length !== 0 && (
       <Box
       sx={{
         overflow: "auto",
       }}
     >
       <Table
         sx={{
           width: { lg: "100%", xs: "1000px" },
         }}
         aria-labelledby="tableTitle"
       >
          <EnhancedTableHead
            totalColumn={["Image No.", "Preview", "Path", "Action"]}
           
            checkbox={true}
          />
          <TableBody>
            {imagesArray.map((row, index) => {
              return (
                <TableRow
                  hover
                  key={index}
                  sx={{ bgcolor: index % 2 === 0 ? "#FFFFFF" : "#FFFAF3" }}
                >
                  <TableCell style={Style.tableCell}>{index + 1}</TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    padding="none"
                    sx={{ width: "200px", pl: 2 }}
                    style={Style.tableCell}
                  >
                    <Box
                      sx={{
                        height: { xs: 100, md: 65 },
                        width: 60,
                        border: "1px solid rgba(61, 46, 87, 0.4)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "7px",
                      }}
                    >
                      <img
                        alt="logo"
                        src={row.src}
                        style={{ height: 50, width: 40 }}
                      />
                    </Box>
                  </TableCell>
                  <TableCell style={Style.tableCell}> {row.path} </TableCell>
                  <TableCell
                    sx={{ display: "flex", alignItems: "center" }}
                    style={Style.tableCell}
                  >
                    <DeleteIcon
                      onClick={row.id === "" ? () => handleRemoveImages(row.path) : () => DeleteMore_Images(row.id,index)}
                      sx={{ color: "red", cursor: "pointer",fontSize: "26px" }}
                    />
                    {row.id && (
                      <Box sx={{ ml: 5 }}>
                        <label htmlFor={`file${index}`}>
                          <img
                            alt="logo"
                            src={require("../../assets/edit.png")}
                            style={{
                              width: "18px",
                              height: "18px",
                              cursor: "pointer",
                            }}
                          />
                        </label>
                        <input
                          id={`file${index}`}
                          type="file"
                          style={{ display: "none" }}
                          onChange={(event) => handleEdit(event, index, row.id)}
                        />
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        </Box>
      )}
      <Toastify />
      <Box
        sx={{
          width: { xs: "90%", sm: "92%", md: "35%", lg: "40%" },
          float: "right",
          display: "flex",
          justifyContent: {xs: "space-between",md:"flex-end"},
          pt: 4,
          mr: {xs:2,md:2,lg:3},
          // bgcolor:"yellow"
        }}
      >
        <Button
          disableRipple
          sx={{
            mr: {md:3},
            pl: "25px", pr: "25px", pt:"10px", pb: "10px",
            fontSize: "18px",
            lineHeight: "21px",
            fontWeight: 400,
            borderRadius: "5px",
            textTransform: "none",
            border: "1px solid #EB5757",
            bgcolor: "#EB5757",
            color: "white",
            "&.MuiButtonBase-root:hover": {
              border: "1px solid #EB5757",
              bgcolor: "#EB5757",
              color: "white",
            },
          }}
          variant="outlined"
          className="btn"
          onClick={()=>{
            navigate("/greeting/cardlisting")
          }}
        >
          Cancel
        </Button>
        <Button
          disableRipple
          sx={{
            pl: "25px", pr: "25px", pt:"10px", pb: "10px",
            fontSize: "18px",
            lineHeight: "21px",
            fontWeight: 400,
            borderRadius: "5px",
            textTransform: "none",
            color: "white",
            bgcolor: "#27AE60",
            border: "1px solid #27AE60",
            "&.MuiButtonBase-root:hover": {
              border: "1px solid #27AE60",
              color: "white",
              bgcolor: "#27AE60",
            },
          }}
          variant="outlined"
          className="btn"
          type="submit"
        >
          Save
        </Button>
      </Box>
    </form>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    getEditViewCardList: (data) => dispatch(getEditViewCardList(data)),
    SaveAddEditCardList: (data) => dispatch(SaveAddEditCardList(data)),
    DeleteMoreImages: (data) => dispatch(DeleteMoreImages(data)),
    getGreetingCategoriesList: (data) => dispatch(getGreetingCategoriesList(data)),
  };
}
export default connect(null, mapDispatchToProps)(AddNewCardListing);

const Style = {
  typographyStyle: {
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "31px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    pb: 3,
  },
  rowBoxStyle: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  inputStyle: {
    width: {
      xs: "90%",
      sm: "90%",
      md: "47%",
      lg: "47%",
      xl: "47%",
    },
    mb: 2
  },
  inputStyle1: {
    width: {
      xs: "90%",
      sm: "90%",
      md: "47%",
      lg: "47%",
      xl: "47%",
    },
    mb: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    color: "#333333",
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#333333",
    border: "none",
    height: "80.85px",
  },
  star: {
    color: "red"
  }
};
