import {
  Box,
  Typography,
  Button,
  FormLabel,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  TextField,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import InputField from "../../components/Custom/Input/InputField";
import { getViewEgiftData, saveData } from "../../redux/action/eGiftCards";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toastify from "../../components/SnackBar/Toastify";
const schema = yup.object().shape({
  title: yup.string().required("Please enter Title"),
  // file_path: yup.string().required("Please Select GIF"),
});
const Form = ({ getViewEgiftData, saveData, Edit, egift_id }) => {
  const navigate = useNavigate();
  const [Img, setImg] = useState("");
  const [Imgerr, setImgerr] = useState(false);
  const [Imgsrc, setImgsrc] = useState("");
  const [alignment, setAlignment] = React.useState("");
  const [fileName, setfileName] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [editdata, setEditData] = useState({
    title: "",
    file_path: "",
  });
  const { control, formState, handleSubmit, setError } = useForm();
  const { isValid, dirtyFields, errors } = formState;
  useEffect(() => {
    if (egift_id) {
      setLoading(true);
      getViewCard();
    }
  }, []);
  let api_data = {
    egift_id: egift_id,
  };
  const getViewCard = () => {
    getViewEgiftData(api_data).then((res) => {
      setLoading(false);
      if (res) {
        setImg(res?.data?.data?.file_path);
        setfileName(
          res?.data?.data?.file_path?.substring(
            res?.data?.data?.file_path?.lastIndexOf("/") + 1
          )
        );
        setImgsrc(res?.data?.data?.file_path);
        setAlignment(res?.data?.data?.is_active === "1" ? "Yes" : "No");
        setEditData({
          title: res?.data?.data?.title,
          file_path: res?.data?.data?.file_path,
        });
      }
    });
  };
  const handleUpload = (e) => {
    setImg(e.target.files[0]);
    setfileName(e.target.files[0].name);
    setImgsrc(URL.createObjectURL(e.target.files[0]));
    setImgerr(false);
  };
  const formik = useFormik({
    initialValues: editdata,
    validationSchema: () => {
      if (Img === "") {
        setImgerr(true);
      }
      return schema;
    },
    onSubmit: (value) => {
      setEditData(value);
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  const onSubmit = async (model) => {
    let item = model;
    item.is_active = alignment === "Yes" ? 1 : 0;
    item.active_formatted = alignment === "Yes" ? "Active" : "In-Active";
    item.file_path = Img;
    item.files_id = egift_id;
    if (Img === "") {
      delete item.file_path;
    }
    if (item.file_path) {
      setLoading(true);
      saveData(item).then((res) => {
        setLoading(false);
        if (res.data.status) {
          toast.success("Updated Successfully!!");
          setTimeout(() => {
            navigate("/e-giftcards");
          }, 2000);
        } else {
          toast.error(res.data.message);
        }
      });
    }
  };
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <form
      style={{ minHeight: "600px" }}
      name="RegisterForm"
      onSubmit={formik.handleSubmit}
    >
      <Box
        sx={{
          width: "100%",
          height: "30%",
          borderRadius: 2,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Effra",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "29px",
            pl: 3,
            pb: 2,
            color: "#000000",
          }}
        >
          {Edit ? "Edit E-Gift Card" : "Add New E-Gift Card"}
        </Typography>
        <Toastify />
        <Box sx={Style.rowBoxStyle}>
          {loading && (
            <Skeleton
              variant="rectangular"
              height={60}
              width={"47%"}
              sx={{ mt: 4 }}
            />
          )}
          {!loading && (
            <Box sx={Style.inputStyle}>
              <FormLabel sx={Style.label}>
                Title
                <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Controller
                name="title"
                control={control}
                sx={{
                  pb: 0,
                  // mt: 10,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    variant="filled"
                    InputProps={{ disableUnderline: true, pt: "10px" }}
                    autoComplete="false"
                    inputProps={{
                      style: {
                        paddingTop: "16px",
                        paddingBottom: "15px",
                      },
                    }}
                    color="primary"
                    placeholder="Enter Title"
                    sx={{
                      width: "100%",
                      border: "none",
                    }}
                  />
                )}
              />
              {formik.errors.title && formik.touched.title ? (
                <p style={{ color: "red" }}>{formik.errors.title}</p>
              ) : null}
            </Box>
          )}
          {loading && (
            <Skeleton
              variant="rectangular"
              height={60}
              width={"47%"}
              sx={{ mt: 4 }}
            />
          )}
          {!loading && (
            <Box sx={Style.inputStyle}>
              <FormLabel sx={Style.label}>
                Upload GIF
                <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Box sx={{ width: "100%", bgcolor: "#F6F6F6" }}>
                <Controller
                  name="file_path"
                  control={control}
                  sx={{
                    pb: 0,
                    // mt: 10,
                  }}
                  render={({ field }) => (
                    <>
                      {!Img && (
                        <Box
                          sx={{ width: "100%", bgcolor: "#F6F6F6", height: 54 }}
                        >
                          <input
                            {...field}
                            style={{ padding: "17px 12px" }}
                            type="file"
                            id="file_path"
                            name="file_path"
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleUpload}
                            value={Img}
                          />
                        </Box>
                      )}
                      {Img && (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              bgcolor: "#F6F6F6",
                              height: 54,
                              display: "flex",
                              alignItems: "center",
                              pl: 2,
                              justifyContent: "space-between",
                              pr: 1,
                            }}
                          >
                            <Chip
                              label={fileName}
                              component="a"
                              // href={mainImg}
                              name="file_path"
                              variant="outlined"
                              value={Img}
                              // clickable
                              onDelete={() => {
                                setImg("");
                                setImgerr(true);
                              }}
                            />
                            <img
                              alt="logo"
                              style={{ height: 50, width: 40 }}
                              src={Imgsrc}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  )}
                />
              </Box>
              {Imgerr && <p style={{ color: "red" }}>Please Select GIF</p>}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width: {
              xs: "90%",
              sm: "90%",
              md: "47%",
              lg: "47%",
              xl: "47%",
            },
            mb: 2,
            // pl: 3,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {loading && (
            <Skeleton
              variant="rectangular"
              height={60}
              width={250}
              sx={{
                mt: 10,
                ml: 3,
              }}
            />
          )}
          {!loading && (
            <Box
              sx={{
                pl: { xs: 2, sm: 3 },
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  mt: "20px",
                  fontWeight: 400,
                  color: "#333333",
                }}
              >
                Is Active
                <span style={{ color: "red" }}>*</span>
              </Typography>
              <Controller
                name="active"
                control={control}
                sx={{
                  pb: 0,
                  // mt: 10,
                }}
                render={() => (
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    sx={{ height: 56 }}
                    aria-label="Is Active"
                  >
                    <ToggleButton
                      color="primary"
                      value="Yes"
                      sx={{ width: "64px" }}
                    >
                      Yes
                    </ToggleButton>
                    <ToggleButton
                      color="standard"
                      value="No"
                      sx={{ width: "64px" }}
                    >
                      No
                    </ToggleButton>
                  </ToggleButtonGroup>
                )}
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "90%", sm: "92%", md: "35%", lg: "40%" },
          float: "right",
          display: "flex",
          justifyContent: { xs: "space-between", md: "flex-end" },
          pt: 4,
          mr: { xs: 2, md: 2, lg: 3 },
        }}
      >
        <Button
          disableRipple
          sx={{
            mr: { md: 3 },
            pl: "25px",
            pr: "25px",
            pt: "10px",
            pb: "10px",
            fontSize: "18px",
            lineHeight: "21px",
            fontWeight: 400,
            borderRadius: "5px",
            textTransform: "none",
            border: "1px solid #EB5757",
            bgcolor: "#EB5757",
            color: "white",
            "&.MuiButtonBase-root:hover": {
              border: "1px solid #EB5757",
              bgcolor: "#EB5757",
              color: "white",
            },
          }}
          variant="outlined"
          className="btn"
          onClick={() => {
            navigate("/e-giftcards");
          }}
        >
          Cancel
        </Button>
        <Button
          disableRipple
          sx={{
            pl: "25px",
            pr: "25px",
            pt: "10px",
            pb: "10px",
            fontSize: "18px",
            lineHeight: "21px",
            fontWeight: 400,
            borderRadius: "5px",
            textTransform: "none",
            color: "white",
            bgcolor: "#27AE60",
            border: "1px solid #27AE60",
            "&.MuiButtonBase-root:hover": {
              border: "1px solid #27AE60",
              color: "white",
              bgcolor: "#27AE60",
            },
          }}
          variant="outlined"
          className="btn"
          type="submit"
        >
          Save
        </Button>
      </Box>
    </form>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getViewEgiftData: (data) => dispatch(getViewEgiftData(data)),
    saveData: (data) => dispatch(saveData(data)),
  };
}
export default connect(null, mapDispatchToProps)(Form);
const Style = {
  typographyStyle: {
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "31px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    pb: 3,
  },
  rowBoxStyle: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  inputStyle: {
    width: {
      xs: "90%",
      sm: "90%",
      md: "47%",
      lg: "47%",
      xl: "47%",
    },
    mb: 2,
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    color: "#333333",
  },
};
