import * as yup from "yup";
import { Box } from "@mui/material";
import { Typography, TextField, FormLabel, Button } from "@mui/material";
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { getServiceCharges, updateServiceCharges } from "../../redux/action/configuration";
import { toast } from "react-toastify";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import Toastify from "../../components/SnackBar/Toastify";

const schema = yup.object().shape({
  charges: yup
    .string()
    .required("Please enter amount")
    .matches(/^[0-9,<.>]*$/, "Please enter valid amount")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
});

const GiftCardAmounts = ({ getServiceCharges, updateServiceCharges }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState({
    charges: "",
  });

  const { control, formState, handleSubmit, setError } = useForm();
  const { isValid, dirtyFields, errors } = formState;
  React.useEffect(() => {
    setLoading(true);
    getServiceCharges().then((res) => {
      setLoading(false);
      if (res.data.status) {
        const result = res.data.data;
        result.map((item) => {
          // return{
          setAmount({ charges: item.meta_value });
          // }
        });
        // setAmount({ charges: result });
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    });
  }, []);

  const onSubmit = async (model) => {
    setLoading(true);
    let data = { meta_value: model.charges };
    updateServiceCharges(data).then((res) => {
      setLoading(false);
      if (res.data.status) {
        const result = res.data.data;
        result.map((item) => setAmount({ charges: item.meta_value }));
      } else {
        setLoading(false);
        toast.error(res.data.message);
      }
    });
  };
  const formik = useFormik({
    initialValues: amount,
    validationSchema: schema,
    onSubmit: (value) => {
      // setAmount({charges:value});
      onSubmit(value);
    },
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{
        width: "100%",
      }}>
      <Typography>Change services charges amount</Typography>
      <LoaderComponent open={loading} />
      <form onSubmit={formik.handleSubmit}>
        <FormLabel>
          Amount
          <span style={{ color: "red" }}>*</span>
        </FormLabel>
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
            color: "#333333",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}>
          <Controller
            name="charges"
            control={control}
            sx={{
              pb: 0,

              // mt: 10,
            }}
            render={({ field }) => (
              <Box
              // sx={{display:'flex'}}
              >
                <TextField
                  name="charges"
                  value={formik.values.charges}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  type="text"
                  variant="filled"
                  InputProps={{
                    disableUnderline: true,
                    pt: "10px",
                    startAdornment: (
                      <Typography
                        sx={{
                          pr: 1,
                          fontSize: "18px",
                          height: "25px",
                          fontWeight: 400,
                          borderRadius: "15px",
                        }}>
                        $
                      </Typography>
                    ),
                  }}
                  autoComplete="false"
                  inputProps={{
                    style: {
                      paddingTop: "16px",
                      paddingBottom: "15px",
                    },
                  }}
                  color="primary"
                  placeholder="0"
                  sx={{
                    width: "100%",
                    border: "none",
                  }}
                />
                {formik.errors.charges && formik.touched.charges ? <p style={{ color: "red" }}>{formik.errors.charges}</p> : null}
              </Box>
            )}
          />

          <Button
            sx={{
              ml: { xs: 5, sm: 3 },
              mr: { xs: 5, sm: 0 },
              mt: 1,
              p: 2,
              pl: 5,
              pr: 5,
              fontSize: "16px",
              height: "25px",
              fontWeight: 500,
              borderRadius: "15px",
              color: "#27AE60",
              textTransform: "none",
              border: "1px solid #27AE60",
              "&.MuiButtonBase-root:hover": {
                border: "1px solid #27AE60",
              },
            }}
            variant="outlined"
            className="btn"
            type="submit">
            Update
          </Button>
        </Box>
      </form>
      <Box>
        {/* <Typography
          sx={{
            pt: 3,
            color: "#3D2E57",
          }}
        >
          Amounts
        </Typography> */}
        {/* <Box>
          {amount.map((item, index) => (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  border: "1px solid gray",
                  width: "7%",
                  textAlign: "center",
                  borderRadius: "5px",
                  p: 1,
                  m: 2,
                }}
              >
                ${item.amount}
              </Box>
              <IconButton onClick={() => deleteAmount(item.amount)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Box>
          ))}
        </Box> */}
        <Toastify />
      </Box>
    </Box>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getServiceCharges: (data) => dispatch(getServiceCharges(data)),
    updateServiceCharges: (data) => dispatch(updateServiceCharges(data)),
  };
};

export default connect(null, mapDispatchToProps)(GiftCardAmounts);
