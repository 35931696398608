import { Api } from "../../../config/request";

export const requestOrders = async (data) => {
    return Api.PostRequest("/admin/orders/list", data);
  };

export const requestOrderDetail = async (data) => {
    return Api.PostRequest("/admin/order/details", data);
  };

  export const requestOrderItems = async (data) => {
    return Api.PostRequest("/admin/order/items/list", data);
  };

export const requestItemDetail = async (data) => {
  return Api.PostRequest("/admin/order/item/details", data);
};
export const requestupdateStatus = async (data) => {
  // return Api.PostRequest("/admin/order/item/details", data);
};
export const requestgetVideos = async (data) => {
  return Api.PostRequest("/admin/get/video/msg", data);
};
export const requestApproveDecline = async (data) => {
  return Api.PostRequest("/admin/approve/decline/video", data);
};

const OrdersApi = {
    requestOrders,
    requestOrderDetail,
    requestOrderItems,
    requestItemDetail,
    requestupdateStatus,
    requestgetVideos,
    requestApproveDecline
};
export default OrdersApi;