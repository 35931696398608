import React,{useEffect,useState} from 'react'
import { useLocation } from 'react-router-dom'
import Form from '../../components/Custom/Form/form'
const Addnewuser = () => {
  const location = useLocation()
  const [Edit,setEdit] = useState(false)
    useEffect(() => {
      if(location.state){
        setEdit(true)
      }
    }, [])
    
    
  return (
    <>
   { Edit&&
    <Form
    EditUser={true}
    users_id={location.state}/>}
     { !Edit&&
    <Form
    EditUser={false}
    users_id={location.state}/>}
    
    </>
  )
}

export default Addnewuser