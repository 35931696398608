import {Api} from '../../../config/request'
export const requestusersdata = async (data) => {
    return Api.PostRequest('/admin/users/list', data);
  };
  export const requestsavedata = async (data) => {
    return Api.PostRequest('/admin/save/users', data);
  };
  export const requestuserdata = async (data) => {
    return Api.PostRequest('/admin/get/user', data);
  };
  export const requestdeleteUser = async (data) => {
    return Api.PostRequest('/admin/remove/users', data);
  };
  export const requestReminder = async (data) => {
    return Api.PostRequest('/admin/users/reminders', data);
  };
  export const requestAddress = async (data) => {
    return Api.PostRequest('/admin/users/addresses', data);
  };
  export const requestUserAddress = async (data) => {
    return Api.PostRequest('/admin/user/address', data);
  };
  export const requestCountryData = async (data) => {
    return Api.PostRequest("/get/countries", data);
  };
  export const requestdeleteAddress = async (data) => {
    return Api.PostRequest("/admin/remove/user/address", data);
  };
  export const requestSaveAddressList = async (data) => {
    return Api.PostRequest("/admin/save/user/address", data);
  }
  export const requestUpdateReminder = async (data) => {
    return Api.PostRequest('/admin/save/user/reminder', data);
  };
  export const requestRemoveReminder = async (data) => {
    return Api.PostRequest('/admin/remove/user/reminder', data);
  };
const UserApi = {
    requestusersdata,
    requestsavedata,
    requestuserdata,
    requestdeleteUser,
    requestReminder,
    requestAddress,
    requestUserAddress,
    requestCountryData,
    requestdeleteAddress,
    requestSaveAddressList,
    requestUpdateReminder,
    requestRemoveReminder
  };
  export default UserApi;