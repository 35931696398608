import { toast } from "react-toastify";
import GreetingApi from "../../services/greetingCards";

export const getGreetingCategories = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestGreetingCategoryData(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const savenewCategory = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestEdit_Add_CategoryData(data);

    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};
export const deleteCategory = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestRemoveCategoryData(data);

    if (response.status) {
      // dispatch(userdata(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const getSpecificCategory = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestgetSpecificCategoryData(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const getGreetingCardListing = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestgetGreetingCardListing(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const deleteCard = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestDeleteCard(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const getEditViewCardList = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestgetEditViewCardList(data);

    if (response.status) {
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const SaveAddEditCardList = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestSaveAddEditCardList(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const getGreetingCategoriesList = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestGreetingCategoryDataList(data);
    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};

export const DeleteMoreImages = (data) => async (dispatch) => {
  try {
    let response = await GreetingApi.requestDeleteMoreImages(data);
    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
};