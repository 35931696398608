import {
  Typography,
  TextField,
  FormLabel,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Skeleton,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import {
  savenewCategory,
  getSpecificCategory,
} from "../../redux/action/greetingCards";
import * as yup from "yup";
import { connect } from "react-redux";
import { useFormik } from "formik";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { toast } from "react-toastify";
import Toastify from "../../components/SnackBar/Toastify";
import { Schema } from "@mui/icons-material";

const schema = yup.object().shape({
  category_name: yup.string().required("Please enter category name"),
  category_image: yup.string(),
});

const AddNew = ({ savenewCategory, getSpecificCategory }) => {
  const location = useLocation();
  const data = { category_id: location.state };
  const [alignment, setAlignment] = React.useState("");
  const [mainImg, setMainImg] = useState("");
  const [Imgsrc, setImgsrc] = useState("");
  const [Imgerr, setImgerr] = useState(false);
  const [editCategorydata, setEditCategoryData] = useState({
    category_name: "",
    category_image: "",
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const { control, formState, handleSubmit, setError } = useForm();
  const { isValid, dirtyFields, errors } = formState;

  React.useEffect(() => {
    if (location?.state === null) {
      setLoading(false);
    } else {
      setLoading(true);
      getSpecificCategory(data).then((res) => {
        setLoading(false);
        if (res.data.status) {
          const result = res.data.data;
          setEditCategoryData({
            category_name: result.category_name,
            category_image: result.file_path,
          });
          setMainImg(result.file_path);
          setImgsrc(result.file_path);
          result.is_active === "0" ? setAlignment("No") : setAlignment("Yes");
        }
      });
    }
  }, []);
  const formik = useFormik({
    initialValues: editCategorydata,
    validationSchema: () => {
      if (mainImg === "") {
        setImgerr(true);
      }
      return schema;
    },
    onSubmit: (value) => {
      setEditCategoryData(value);
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  const onSubmit = async (model) => {
    model.category_image = mainImg;
    let active = { is_active: alignment === "Yes" ? 1 : 0 };
    Object.assign(model, { category_id: location.state }, active);
    if (mainImg === "") {
      delete model.category_image;
    }
    if (model.category_image) {
      setLoading(true);
      savenewCategory(model).then((res) => {
        if (res.data.status) {
          setLoading(false);
          toast.success(res.data.message);
          setTimeout(() => {
            navigate("/greeting/categories");
          }, 2000);
        } else {
          setLoading(false);
          toast.error(res.data.message);
        }
      });
    }
  };
  const handleUpload = (e) => {
    setMainImg(e.target.files[0]);
    setImgsrc(URL.createObjectURL(e.target.files[0]));
    setImgerr(false);
  };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Effra",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "29px",
          color: "#3D2E57",
          pb: 2,
          // pl:3
        }}
      >
        {location?.state === null ? "Add new category" : "Edit category"}
      </Typography>
      <Box
        sx={{
          width: "100%",
          color: "#333333",
        }}
      >
        <LoaderComponent open={loading} />

        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onSubmit={formik.handleSubmit}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "space-between",
            }}
          >
            {loading && (
              <Skeleton variant="rectangular" height={60} width={450} />
            )}
            {!loading && (
              <Box
                sx={{
                  display: "flex",
                  width: { xs: "100%", md: "47%" },
                  flexDirection: "column",
                  mb: 2,
                }}
              >
                <FormLabel
                  sx={{
                    color: "#333333",
                    fontSize: "20px",
                    fontWeight: 400,
                  }}
                >
                  Category name
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>

                <Controller
                  name="category_name"
                  control={control}
                  sx={{
                    pb: 0,
                    // mt: 10,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      name="category_name"
                      value={formik.values.category_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      autoComplete="false"
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter category name"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                    />
                  )}
                />
                {formik.errors.category_name && formik.touched.category_name ? (
                  <p style={{ color: "red" }}>{formik.errors.category_name}</p>
                ) : null}
              </Box>
            )}
            {loading && (
              <Skeleton variant="rectangular" height={60} width={450} />
            )}
            {!loading && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: { xs: "100%", md: "47%" },
                }}
              >
                <FormLabel
                  sx={{
                    color: "#333333",
                    fontSize: "20px",
                    fontWeight: 400,
                  }}
                >
                  Category Image
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="category_image"
                  control={control}
                  // value={formik.values.category_image}
                  sx={{
                    pb: 0,
                    // mt: 10,
                  }}
                  render={({ field }) => (
                    <>
                      {!mainImg && (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              bgcolor: "#F6F6F6",
                              height: 60,
                            }}
                          >
                            <input
                              {...field}
                              style={{ padding: "17px 12px" }}
                              type="file"
                              id="category_image"
                              name="category_image"
                              accept="image/png, image/gif, image/jpeg"
                              onChange={handleUpload}
                              value={mainImg}
                            />
                          </Box>
                        </>
                      )}
                      {mainImg && (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              bgcolor: "#F6F6F6",
                              height: 60,
                              display: "flex",
                              alignItems: "center",
                              pl: 2,
                              justifyContent: "space-between",
                              pr: 1,
                            }}
                          >
                            <Chip
                              label="category_image"
                              component="a"
                              // href={mainImg}
                              variant="outlined"
                              // clickable
                              onDelete={() => {
                                setMainImg("");
                                setImgerr(true);
                              }}
                            />
                            <img
                              alt="logo"
                              style={{ height: 50, width: 40 }}
                              src={Imgsrc}
                            />
                          </Box>
                        </>
                      )}
                    </>
                  )}
                />
                {Imgerr && (
                  <p style={{ color: "red" }}>Please Select Category Image</p>
                )}
              </Box>
            )}
          </Box>
          {loading && (
            <Skeleton
              variant="rectangular"
              height={60}
              width={250}
              sx={{
                mt: 3,
              }}
            />
          )}
          {!loading && (
            <>
              <Typography
                sx={{
                  color: "#333333",
                  fontSize: "20px",
                  fontWeight: 400,
                  mt: "20px",
                }}
              >
                Is Active
              </Typography>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                sx={{ height: 56 }}
                aria-label="Is Active"
              >
                <ToggleButton
                  color="primary"
                  value="Yes"
                  sx={{ width: "64px" }}
                >
                  Yes
                </ToggleButton>
                <ToggleButton
                  color="standard"
                  value="No"
                  sx={{ width: "64px" }}
                >
                  No
                </ToggleButton>
              </ToggleButtonGroup>
            </>
          )}
          <Box
            sx={{
              width: { xs: "100%", md: "30%" },
              display: "flex",
              justifyContent: { xs: "space-between", md: "flex-start" },
              pt: 4,
            }}
          >
            <Button
              disableRipple
              sx={{
                mr: { md: 3 },
                pl: "25px",
                pr: "25px",
                pt: "10px",
                pb: "10px",
                fontSize: "18px",
                lineHeight: "21px",
                fontWeight: 400,
                borderRadius: "5px",
                textTransform: "none",
                border: "1px solid #EB5757",
                bgcolor: "#EB5757",
                color: "white",
                "&.MuiButtonBase-root": {
                  fontSize: "18px",
                },
                "&.MuiButtonBase-root:hover": {
                  border: "1px solid #EB5757",
                  bgcolor: "#EB5757",
                  color: "white",
                },
              }}
              variant="outlined"
              className="btn"
              onClick={() => {
                navigate("/greeting/categories");
              }}
            >
              Cancel
            </Button>
            <Button
              disableRipple
              sx={{
                pl: "25px",
                pr: "25px",
                pt: "10px",
                pb: "10px",
                lineHeight: "21px",
                fontWeight: 400,
                borderRadius: "5px",
                textTransform: "none",
                color: "white",
                bgcolor: "#27AE60",
                border: "1px solid #27AE60",
                "&.MuiButtonBase-root": {
                  fontSize: "18px",
                },
                "&.MuiButtonBase-root:hover": {
                  border: "1px solid #27AE60",
                  color: "white",
                  bgcolor: "#27AE60",
                },
              }}
              variant="outlined"
              className="btn"
              type="submit"
            >
              Save
            </Button>
          </Box>
        </form>
      </Box>
      <Toastify />
    </Box>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    savenewCategory: (data) => dispatch(savenewCategory(data)),
    getSpecificCategory: (data) => dispatch(getSpecificCategory(data)),
  };
};

export default connect(null, mapDispatchToProps)(AddNew);
