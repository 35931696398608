/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import TopBox from "../../components/Custom/Boxes/TopBox";
import { getdata, deleteuserData } from "../../redux/action/users";
import { connect } from "react-redux";
import { Button, Pagination, Skeleton, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { EnhancedTableHead } from "../../components/Custom/Table/TableHeader";
import AlertDialog from "../../components/Custom/DialogBox/Dialog";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { toast } from "react-toastify";
import Toastify from "../../components/SnackBar/Toastify";
import { storage } from "../../config/storage";
const Users = ({ getdata, deleteuserData }) => {
  const [loading, setLoading] = useState(true);
  const [deleteData, setDeleteData] = useState();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("price");
  const [selected, setSelected] = React.useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [perv_search_val,setPerv_Search_val] = React.useState("");
  const [search_val, setSearch_val] = React.useState("");
  const [Empty, setEmpty] = useState(false);
  const navigate = useNavigate();
  const [model, setModal] = React.useState(false);
  let length = 6;
  let data = {
    start: start,
    length: length,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getuserData();
  }, []);
  const getuserData = () => {
    getdata(data).then((res) => {
      setLoading(false);
      if (res?.data?.data?.total_records === 0) {
        setRows(res.data.data.users);
        setPages(res.data.data.pages);
        setEmpty(true);
      }
      if (res.data.status) {
        setEmpty(false);
        setPages(res.data.data.pages);
        setRows(res.data.data.users);
      } else {
      }
    });
  };
  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }
    setSelected([]);
    getuserData();
  };
  const onSubmit = () => {
    if ((search_val !== "" && search_val.trim().length !== 0) || perv_search_val !== "") {
    setPerv_Search_val(search_val);
    setLoading(true);
    setStart(0);
    setPage(1);
    data = {
      start: 0,
      length: length,
    };
    let searchVal = { search_val: search_val };
    Object.assign(data, searchVal);
    if (order && orderBy) {
      let sort_column = { sort_column: orderBy };
      let sort = { sort_by: order };
      Object.assign(data, sort_column, sort);
    }
    getuserData();
    setSelected([]);
   }
  };

  const handleRequestSort = (event, property) => {
    let sort, sort_column;
    let isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder("desc");
      sort = { sort_by: "asc" };
      sort_column = { sort_column: property };
      setOrderBy(property);
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      sort_column = { sort_column: property };
      sort = { sort_by: order };
    }
    Object.assign(data, sort_column, sort);
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }
    getuserData();
    setSelected([]);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((user) => user.users_id);
      setSelected(newSelected);

      const arr1 = newSelected.map((userId) => {
        return { id: userId };
      });
      setDeleteData(arr1);
    } else setSelected([]);
  };
  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    const arr1 = newSelected.map((userId) => {
      return { id: userId };
    });
    setDeleteData(arr1);
  };
  const deleteUser = () => {
    let data = { users: deleteData };
    setLoading(true);
    deleteuserData(data).then((res) => {
      if (res?.data?.status) {
        getuserData();
      setModal(false);
      setSelected([]);
      setDeleteData([]);
      toast.success("Deleted Successfully!!")
    } else {
      setLoading(true);
      setSelected([]);
      setDeleteData([])
      setModal(false)
      toast.error(res.data.message);
    }
    });
  };

  const isSelected = (title) => selected.indexOf(title) !== -1;

  const handleEdit = (id) => {
    navigate("/Users/addnewuser", { state: id });
  };
  return (
    <>
        <TopBox
          headerText={"Users"}
          button_one={"Search"}
          button_two={"Delete"}
          button_three={"Add new"}
          onClick="/Users/addnewuser"
          deleteAPI={() => {
            if (deleteData !== undefined || selected === []) {
              setModal(true);
            }
          }}
          onSubmit={onSubmit}
          perv_search_val={perv_search_val}
          search_val={search_val}
          setSearch_val={setSearch_val}
          numSelected={selected.length}
        />
          <AlertDialog
            title={"You are about to delete User"}
            text={"This will delete the User from the list"}
            onClickButton={() => deleteUser()}
            onClickButtonCancel={() => {
              setModal(false)

            }}
            open={model}
          />
        <LoaderComponent open={loading} />
        {Empty && (
          <Typography
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 20,
              pb: 20,
              fontSize: "35px",
              color: "#A8A8A8",
              fontWeight: "700",
            }}
          >
            No User Found
          </Typography>
        )}
        {!Empty && (
      <Box sx={{ width: "100%" ,overflow:'auto'}}>
      <Table>
            <EnhancedTableHead
              totalColumn={[
                "First Name",
                "Email",
                "Is Email Verified",
                "Phone",
                "Is Phone Verified",
                "Action",
              ]}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.users_id);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.users_id}
                    selected={isItemSelected}
                    sx={{bgcolor: index % 2 === 0 ? "#FFFFFF" : "#FFFAF3"}}
                  >
                    <TableCell>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && (
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row.users_id)}
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={Style.tableCell1}
                      style={{
                        paddingLeft: 20,
                      }}
                    >
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.first_name}
                    </TableCell>
                    <TableCell sx={Style.tableCell1}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.email}
                    </TableCell>
                    <TableCell sx={Style.tableCell1} >
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.email_verification}
                    </TableCell>
                   
                    <TableCell sx={Style.tableCell}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.phone}
                    </TableCell>
                    <TableCell sx={Style.tableCell1}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.phone_verification}
                    </TableCell>
                    <TableCell
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 95,
                        pt:1,pb:1,pl:0,
                      }}
                    >
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={100}
                          width={100}
                        />
                      )}
                      {!loading && (
                        <>
                          <img
                            src={require("../../assets/edit.png")}
                            style={{
                              width: "18px",
                              height: "18px",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleEdit(row.users_id);
                            }}
                          />
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              fontSize: "16px",
                              width: "100px",
                              height: "25px",
                              color: "white",
                              bgcolor: "#FF8D2A",
                              cursor: "pointer",
                              // mt:1,mb:1,
                              boxShadow: "none",
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: "#FF8D2A",
                                boxShadow: "none",
                              },
                            }}
                            onClick={() => {
                              navigate("/Users/reminder", { state: row });
                            }}
                          >
                            Reminders
                          </Button>
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: "none",
                              fontSize: "16px",
                              width: "100px",
                              height: "25px",
                              color: "white",
                              bgcolor: "#FF8D2A",
                              cursor: "pointer",
                              boxShadow: "none",
                              "&.MuiButtonBase-root:hover": {
                                bgcolor: "#FF8D2A",
                                boxShadow: "none",
                              },
                            }}
                            onClick={() => {
                              navigate("/Users/address", { state: row });
                            }}
                          >
                            Addresses
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: 3,
          }}
        >
          {pages > 1 && (
            <Pagination
              count={pages}
              page={page}
              boundaryCount={1}
              onChange={handlePageChange}
              sx={{button:{fontSize:'16px'}}}
              // siblingCount={{xs:1,md:2}}
            />
          )}
        </Box>
        <Toastify/>
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getdata: (data) => dispatch(getdata(data)),
    deleteuserData: (data) => dispatch(deleteuserData(data)),
  };
};

export default connect(null, mapDispatchToProps)(Users);

const Style = {
  tableCell1: {
    maxWidth: "135px",
    width: "140px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 400,
    color: "#000000"
  },
  tableCell: {
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 400,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    // minWidth: "125px",
    maxWidth: "100px",
    color: "#000000"
  },
};
