import { Api } from "../../../config/request";

export const requestGiftAmountList = async (data) => {
  return Api.PostRequest("/admin/gift/cards/list", data);
};
export const requestAddgiftamount = async (data) => {
  return Api.PostRequest("/admin/save/gift/card", data);
};
export const requestDeletegiftamount = async (data) => {
  return Api.PostRequest("/admin/remove/gift/cards", data);
};
export const requestgetamount = async (data) => {
return Api.PostRequest("/admin/get/gift/card", data);
};
const GiftApi = {
  requestGiftAmountList,
  requestAddgiftamount,
  requestDeletegiftamount,
  requestgetamount
};
export default GiftApi;
