/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Skeleton,
} from "@mui/material";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { EnhancedTableHead } from "../../components/Custom/Table/TableHeader";
import { Link, useLocation } from "react-router-dom";
import { FILE_TYPE } from "../../config/mockData";
import {
  getOrderDetail,
  getOrderItems,
  updateStatus,
  getVideos,
} from "../../redux/action/orders";
import Toastify from "../../components/SnackBar/Toastify";
import { toast } from "react-toastify";
import StatusDialog from "../../components/Custom/DialogBox/StatusDialog";

const OrderDeclined = ({
  getOrderDetail,
  getOrderItems,
  updateStatus,
  getVideos,
}) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [Empty, setEmpty] = useState(false);
  const length = 5;
  let data = {
    length: length,
  };
  const [newState1, setnewState1] = useState({});
 
  const getData = (data) => {
    Object.assign(data, { video_review_status: 3 });
    getVideos(data).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        if ((res?.data?.data).length === 0) {
          setEmpty(true);
          // setPages(1);
        } else {
          setEmpty(false);
          setPages(res?.data?.data?.pages);
        }
        setnewState1(res?.data?.data);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    setLoading(true);
    let sort, sort_column;
    let isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder("desc");
      sort = { sort_by: "asc" };
      sort_column = { sort_column: property };
      setOrderBy(property);
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      sort_column = { sort_column: property };
      sort = { sort_by: order };
    }
    Object.assign(data, sort_column, sort, { start: start });
    getData(data);
  };

  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    Object.assign(data, { start: a });
    getData(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    Object.assign(data, { start: 0 });
    getData(data);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
      }}
    >
      <LoaderComponent open={loading} />

      {Empty && (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 20,
            pb: 20,
            fontSize: "35px",
            color: "#A8A8A8",
            fontWeight: "700",
          }}
        >
          No Record Found
        </Typography>
      )}
      {!Empty && (
        <Box
          sx={{
            bgcolor: "white",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            width: "100%",
            borderRadius: 4,
            justifyContent: "center",
            mb: 5,
          }}
        >
          <Box sx={{ overflow: "auto", ml: 1, mr: 7, mt: 1 }}>
            <Table sx={{ width: { lg: "100%", xs: "1000px" } }}>
              <EnhancedTableHead
                totalColumn={["Video", "Order Number", "Declined Reason", "Scheduled Date"]}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                checkbox={true}
              />
              <TableBody>
                {newState1?.videos?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#FFFFFF" : "#FFFAF3" }}
                  >
                    <TableCell sx={{ width: "250px" }}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          sx={{ height: { xs: 100, md: 65 } }}
                          width={60}
                        />
                      )}
                      {!loading && (
                        <Box
                          sx={{
                            height: { xs: 80, md: 100 },
                            maxWidth: 200,

                            border: "1px solid rgba(61, 46, 87, 0.4)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "7px",
                          }}
                        >
                          <video width="150" height="75" controls>
                            <source src={item?.file_path} />
                          </video>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                      {loading && (
                        <Skeleton variant="rectangular" height={50} />
                      )}
                      {!loading && item?.order_number}
                    </TableCell>
                    <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                      {loading && (
                        <Skeleton variant="rectangular" height={50} />
                      )}
                      {!loading && item?.decline_reason}
                    </TableCell>
                    <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                      {loading && (
                        <Skeleton variant="rectangular" height={50} />
                      )}
                      {!loading && item?.post_on_or_after_date?item?.post_on_or_after_date:item.post_date}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Toastify />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              p: 3,
            }}
          >
            {pages > 1 && (
              <Pagination
                count={pages}
                page={page}
                //   boundaryCount={2}
                sx={{ button: { fontSize: "16px" } }}
                onChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderDetail: (data) => dispatch(getOrderDetail(data)),
    getOrderItems: (data) => dispatch(getOrderItems(data)),
    updateStatus: (data) => dispatch(updateStatus(data)),
    getVideos: (data) => dispatch(getVideos(data)),
  };
}
export default connect(null, mapDispatchToProps)(OrderDeclined);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "24px",
    bgcolor: "#3D2E57",
    color: "white",
    display: "flex",
    borderRadius: "5px",
    p: 2,
    pl: { xs: 2, sm: 5 },
    mb: 3,
    mt: 3,
    ml: { xs: 1, sm: 3 },
    mr: { xs: 1, sm: 3 },
  },
  typographyStyle1: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginBottom: "10px",
    minWidth: "117.93px",
    color: "#000000",
  },
  tableCell: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#000000",
  },
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 400,
  },
  linkButton: {
    maxWidth: "102px",
    paddingTop: "5.5px",
    paddingBottom: "5.5px",
    borderRadius: "5px",
    backgroundColor: "#FF8D2A",
    color: "white",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    cursor: "pointer",
  },
};
