/* eslint-disable no-unused-vars */
import {
    Box,
    Button,
    Typography,
    TextField,
    FormLabel,
    IconButton,
  } from "@mui/material";
  import * as yup from "yup";
  import { removeReminder, updateReminder } from "../../redux/action/users";
  import { connect } from "react-redux";
  import React, { useState, useEffect } from "react";
  import { useFormik } from "formik";
  import LoaderComponent from "../../components/Loader/LoaderComponent";
  import { toast } from "react-toastify";
  import Toastify from "../../components/SnackBar/Toastify"
  import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
  } from "@mui/material";
  
  const schema = yup.object().shape({
    event_name: yup.string().required("Please enter your Event name"),
    event_date: yup.string().required("Please enter your Event Date"),
  
    reminder_date: yup.string().required("Please enter your Reminder date"),
  });
  
  const ViewDetails = ({
    item,
    users_id,
    updateReminder,
    removeReminder,
    again,
  }) => {
    const [details, SetDetails] = useState(false);
    const [open, setOpen] = useState(false);
    const [popup, setPopup] = useState(false);
    const [value, setValue] = useState(item);
    const handleClickOpen = () => {
      setPopup(true);
    };
  
    const handleClose = () => {
      setPopup(false);
      setOpen(true);
      let api_data = { reminder_id : item?.reminder_id }
      removeReminder(api_data).then((res) => {
          setOpen(false);
          if (res.data.status) {
          toast.success("Removed Successfully");
          again("aa");
          SetDetails(!details);
        }
      });
    };
  
    const viewSubDetail = (item, index) => {
      SetDetails(!details);
    };
    useEffect(() => {}, []);
    const formik = useFormik({
      initialValues: value,
      validationSchema: schema,
      onSubmit: (value) => {
        Submit(value);
      },
      enableReinitialize: true,
    });
      let api_data = { reminder_id: item?.reminder_id, users_id: users_id};
      const Submit = (value) => {
      setOpen(true);
      Object.assign(value, api_data);
      updateReminder(value).then((res) => {
          setOpen(false);
          if (!res.data.code) {
          toast.error(res.data.message);
          SetDetails(!details);
        }
        if (res.data.status) {
          toast.success("Updated Successfully");
          again("aa");
          SetDetails(!details);
        }
      });
    };
    const Remove = () => {
      handleClickOpen();
    };
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <LoaderComponent open={open} />
        <Box
          sx={{
            width: "100%",
            borderRadius: "4px",
            border: "1px solid rgba(61, 46, 87, 0.4)",
            display: "flex",
            flexDirection: "row",
            height: 60,
            cursor: "pointer",
          }}
          onClick={viewSubDetail}
        >
          <Toastify/>
          <Typography
            sx={{
              p: 2,
              width: "100%",
              fontSize:"20px",
              fontWeight:400
            }}
          >
            {item.event_name && item.event_name}
            {!item.event_name && "New Reminder"}
          </Typography>
          {details === false && (
            <Box
              sx={{
                p: {xs:1.5,md:1.3},
                pr: 4,
              }}
            >
              <IconButton
                sx={{
                  color: "#219653",
                  border: "1.5px solid #219653",
                  height: {xs:"30px",md:"40px"},
                  width: {xs:"30px",md:"40px"},
                  fontSize: {xs:"30px",md:"35px"},
                  pt: {xs:1.5,md:1.2},
                }}
              >
                +
              </IconButton>
            </Box>
          )}
          {details === true && (
            <Box
              sx={{
                p: {xs:1.5,md:1.3},
                pr: 4,
              }}
            >
              <IconButton
                sx={{
                  color: "#EB5757",
                  border: "1.5px solid #EB5757",
                  height: {xs:"30px",md:"40px"},
                  width: {xs:"30px",md:"40px"},
                  fontSize: {xs:"30px",md:"35px"},
                  pb: {xs:1.5,md:1.7},
                }}
                
              >
                -
              </IconButton>
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "block",
          }}
        >
          {details && (
            <Box
              sx={{
                pt: 2,
                minHeight: 250,
              }}
            >
              <form onSubmit={formik.handleSubmit} >
                <Box sx={Style.rowBoxStyle}>
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Event Name
                      <span style={Style.star}>*</span>
                    </FormLabel>
  
                    <TextField
                      name="event_name"
                      value={formik.values.event_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                        },
                      }}
                      color="primary"
                      placeholder="Enter Event Name here"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                      autoComplete="false"
                    />
                    {formik.errors.event_name ? (
                      <p style={Style.validationStyle}>{formik.errors.event_name}</p>
                    ) : null}
                    <p style={{ fontSize: "14px",fontWeight: 400 }}>
                      We suggest using an event codename to avoid this reminder
                      being discovered.{" "}
                    </p>
                  </Box>
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Event Date
                      <span style={Style.star}>*</span>
                    </FormLabel>
  
                    <TextField
                      name="event_date"
                      value={formik.values.event_date}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      type="date"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px" }}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                          textTransform: "uppercase",
                        },
                      }}
                      color="primary"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                      autoComplete="false"
                    />
                    {formik.errors.event_date ? (
                      <p style={Style.validationStyle}>{formik.errors.event_date}</p>
                    ) : null}
                  </Box>
                </Box>
                
                  <Box sx={Style.inputStyle}>
                    <FormLabel sx={Style.label}>
                      Reminder Date
                      <span style={Style.star}>*</span>
                    </FormLabel>
                    <TextField
                      name="reminder_date"
                      value={formik.values.reminder_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="date"
                      variant="filled"
                      InputProps={{ disableUnderline: true, pt: "10px", }}
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                        max: formik.values.event_date,
                        style: {
                          paddingTop: "16px",
                          paddingBottom: "15px",
                          textTransform: "uppercase",
                        },
                      }}
                      color="primary"
                      placeholder="Enter Reminder Date here"
                      sx={{
                        width: "100%",
                        border: "none",
                      }}
                      autoComplete="false"
                    />
                    {formik.errors.reminder_date ? (
                      <p style={Style.validationStyle}>
                        {formik.errors.reminder_date}
                      </p>
                    ) : null}
                  </Box>
                <Box
                sx={{
                  width: {xs:"100%",md:"25%",lg:"20%"},
                  float: "left",
                  display: "flex",
                  justifyContent: "space-between",
                  pt: 4,
                }}
              >
                <Button
                  disableRipple
                  sx={{
                    width: {xs: "25%",md:"45%"},
                    p: 1,
                    fontSize: '16px',
                    lineHeight: '19px',
                    fontWeight: 700,
                    borderRadius: "15px",
                    textTransform: "none",
                    color: '#3D2E57',
                    border: "1px solid #3D2E57",
                    "&.MuiButtonBase-root:hover": {
                      border: "1px solid #3D2E57",
                    },
                  }}
                  variant="outlined"
                  className="btn"
                  type="submit"
                >
                  Update
                </Button>
                <Button
                  disableRipple
                  sx={{
                    width: {xs:"25%",md:"45%"},
                    p: 1,
                    fontSize: '16px',
                    lineHeight: '19px',
                    fontWeight: 700,
                    borderRadius: "15px",
                    textTransform: "none",
                    border: "1px solid #EB5757",
                    color: "#EB5757",
                    "&.MuiButtonBase-root:hover": {
                      border: "1px solid #EB5757",
                    },
                  }}
                  variant="outlined"
                  className="btn"
                  onClick={Remove}
                >
                  Remove
                </Button>
              </Box>
              </form>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", ml: 10 }}>
          <Dialog
            open={popup}
            sx={{ ml: 8, mt: 5 }}
          >
            <DialogContent
              sx={{
                width: 500,
              }}
            >
              <DialogContentText
                sx={{
                  fontSize: "24px",
                }}
              >
                Are you sure ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setPopup(false);
                }}
              >
                No
              </Button>
              <Button onClick={handleClose} autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    );
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      updateReminder: (data) => dispatch(updateReminder(data)),
      // getReminders: () => dispatch(getReminders()),
      removeReminder: (reminder_id) => dispatch(removeReminder(reminder_id)),
    };
  }
  export default connect(null, mapDispatchToProps)(ViewDetails);
  
  const Style = {
    typographyStyle: {
      fontSize: "26px",
      fontWeight: "300",
      lineHeight: "31px",
      letterSpacing: "0em",
      textAlign: "center",
      minHeight: "70px",
      color: "#3D2E57",
      display: "flex",
      pl: { lg: "2%", xs: "4%", sm: "5%", md: "2%" },
    },
    typographyStyle1: {
      fontSize: "22px",
      lineHeight: "26px",
      fontWeight: "400",
      marginRight: "10px",
    },
    typographyStyle2: {
      fontSize: "18px",
      lineHeight: "21px",
      fontWeight: "400",
    },
    tableCell: {
      fontSize: "18px",
      fontWeight: "400",
      color: "#3D2E57",
      borderRadius: 5,
    },
    rowBoxStyle: {
      width: "100%",
      display: "flex",
      flexDirection:{xs:"column",md:"row"},
      justifyContent: "space-between",
    },
    inputStyle: {
      width: {
        xs: "100%",
        sm: "100%",
        md: "48%",
        lg: "49%",
        xl: "49%",
      },
      mb: 2,
    },
    label: {
      fontSize: "18px",
      fontWeight:400,
      color: "#333333",
    },
    star: {
      color: "red",
    },
    validationStyle: {
      color: "red",
      margin: "10px",
    },
  };
  