import { Api } from "../../../config/request";
export const requestServiceCharges = async (data) => {
    return Api.PostRequest('/admin/get/service/charge', data);
  };
  export const requestUpdateServiceCharges = async (data) => {
    return Api.PostRequest('/admin/update/service/charge', data);
  };

const ConfigurationApi = {
  requestServiceCharges,
  requestUpdateServiceCharges,
  };
  export default ConfigurationApi;