/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  Box,
  TableRow,
  Pagination,
  Typography,
  Skeleton,
} from "@mui/material";
import TopBox from "../../components/Custom/Boxes/TopBox";
import { EnhancedTableHead } from "../../components/Custom/Table/TableHeader";
import { getOrders } from "../../redux/action/orders";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LoaderComponent from "../../components/Loader/LoaderComponent";

const Orders = ({ getOrders }) => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [perv_search_val, setPerv_Search_val] = React.useState("");
  const [search_val, setSearch_val] = React.useState("");
  const [Empty, setEmpty] = useState(false);
  const [sortby, setsortby] = React.useState("");
  let length = 6;
  let data = {
    start: start,
    length: length,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getuserData();
  }, []);
  const getuserData = () => {
    getOrders(data).then((res) => {
      setLoading(false);
      if (res?.data?.data?.total_records === 0) {
        setRows(res.data.data.orders_list);
        setPages(1);
        setEmpty(true);
        setLoading(false);
      }
      if (res.data.status) {
        setEmpty(false);
        setPages(res.data.data.pages);
        setRows(res.data.data.orders_list);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };
  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    if (order && orderBy) {
      let sort_column = { sort_column: orderBy };
      let sort = { sort_by: order };
      Object.assign(data, sort_column, sort);
    }
    if (sortby) {
      let sort = { order_status: sortby };
      Object.assign(data, sort);
    }
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }
    setSelected([]);
    getuserData();
  };
  const onSubmit = () => {
    if (
      (search_val !== "" && search_val.trim().length !== 0) ||
      perv_search_val !== ""
    ) {
      setPerv_Search_val(search_val);
      setLoading(true);
      setStart(0);
      setPage(1);
      data = {
        start: 0,
        length: length,
      };
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
      if (order && orderBy) {
        let sort_column = { sort_column: orderBy };
        let sort = { sort_by: order };
        Object.assign(data, sort_column, sort);
      }
      if (sortby) {
        let sort = { order_status: sortby };
        Object.assign(data, sort);
      }
      getuserData();
      setSelected([]);
    }
  };

  const handleRequestSort = (event, property) => {
    setLoading(true);
    let sort, sort_column;
    let isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder("desc");
      sort = { sort_by: "asc" };
      sort_column = { sort_column: property };
      setOrderBy(property);
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      sort_column = { sort_column: property };
      sort = { sort_by: order };
    }
    Object.assign(data, sort_column, sort);
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }
    if (sortby) {
      let sort = { order_status: sortby };
      Object.assign(data, sort);
    }
    getuserData();
    setSelected([]);
  };

  const handleChange = (event) => {
    setLoading(true);
    setsortby(event?.target?.value);
    let sort = { order_status: event.target.value };
    setStart(0);
    setPage(1);
    data = {
      start: 0,
      length: length,
    };
    if (order && orderBy) {
      let sort_column = { sort_column: orderBy };
      let sort = { sort_by: order };
      Object.assign(data, sort_column, sort);
    }
    if (event.target.value === "") {
    } else {
      Object.assign(data, sort);
    }
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal, sort);
    }
    setSelected([]);
    getuserData();
  };
  return (
    <>
      <TopBox
        button_one={"Search"}
        button_two={"Delete"}
        button_three={"Add new"}
        onClick="/orders/addnew"
        orders={true}
        onSubmit={onSubmit}
        perv_search_val={perv_search_val}
        search_val={search_val}
        setSearch_val={setSearch_val}
        numSelected={selected.length}
        handleChange={handleChange}
        value={sortby}
      />
      <LoaderComponent open={loading} />
      {Empty && (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 20,
            pb: 20,
            fontSize: "35px",
            color: "#A8A8A8",
            fontWeight: "700",
          }}
        >
          No User Found
        </Typography>
      )}
      {!Empty && (
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          <Table
            sx={{
              width: { lg: "100%", xs: "1000px" },
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              totalColumn={[
                "Order no",
                "Customer Name",
                "Price",
                "Status",
                "Order Date",
                "Action",
              ]}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              checkbox={true}
            />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#FFFFFF" : "#FFFAF3" }}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "200px", pl: 2.5 }}
                      style={Style.tableCell1}
                    >
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.order_number}
                    </TableCell>
                    <TableCell style={Style.tableCell1}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.customer_name}
                    </TableCell>
                    <TableCell style={Style.tableCell1}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && `$ ${row.total_amount}`}
                    </TableCell>
                    <TableCell
                      style={Style.tableCell2}
                      sx={{
                        color:
                          row?.status.toLowerCase() === "completed"
                            ? "#219653"
                            : "#FF8D2A",
                      }}
                    >
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.status}
                    </TableCell>
                    <TableCell style={Style.tableCell}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.created_at}
                    </TableCell>
                    <TableCell style={Style.tableCell}>
                      <Link
                        to={"/orders/orderdetail"}
                        state={{
                          id: row.orders_id,
                        }}
                        style={{
                          textTransform: "none",
                          color: "white",
                          textDecoration: "none",
                          padding: "5.5px 35px",
                          backgroundColor: "#FF8D2A",
                          borderRadius: "5px",
                        }}
                      >
                        View
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          p: { xs: 0, md: 3 },
        }}
      >
        {pages > 1 && (
          <Pagination
            count={pages}
            page={page}
            boundaryCount={1}
            onChange={handlePageChange}
            sx={{
              button: { fontSize: "16px" },
              "&.root.Mui-disabled": {
                fontSize: "28px",
              },
            }}
            siblingCount={1}
          />
        )}
        {/* </Paper> */}
      </Box>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getOrders: (data) => dispatch(getOrders(data)),
  };
}
export default connect(null, mapDispatchToProps)(Orders);

const Style = {
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    // flex:'wrap',
    lineHeight: "19px",
    color: "#000000",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    border: "none",
  },
  tableCell2: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    border: "none",
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#000000",
    border: "none",
  },
};
