/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Button,
  Skeleton,
} from "@mui/material";
import { TextareaAutosize } from "@mui/base";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { EnhancedTableHead } from "../../components/Custom/Table/TableHeader";
import { Link, useLocation } from "react-router-dom";
import { FILE_TYPE } from "../../config/mockData";
import {
  getOrderDetail,
  getOrderItems,
  ApproveDecline,
  getVideos,
} from "../../redux/action/orders";
import StatusDialog from "../../components/Custom/DialogBox/StatusDialog";
import Toastify from "../../components/SnackBar/Toastify";
import { toast } from "react-toastify";

const OrderPending = ({
  getOrderDetail,
  getOrderItems,
  updateStatus,
  getVideos,
  ApproveDecline,
}) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [Empty, setEmpty] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [item_id, setItem_id] = React.useState("");
  const [value, setValue] = React.useState("Pending");
  const [value1, setValue1] = React.useState("Accept");
  const [video, setVideo] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [reason, setReason] = React.useState(false);
  const [err, setErr] = React.useState(false);
  const length = 5;
  let data = {
    length: length,
  };
  const [newState, setnewState] = useState({
    order_details: {
      order_number: "",
      total_amount: "",
      created_at: "",
      status: "",
    },
  });
  const [newState1, setnewState1] = useState({});

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleChange1 = (e) => {
    setValue1(e.target.value);
    if (e.target.value === "Decline") {
      setReason(true);
    } else {
      setReason(false);
    }
  };
  const handleChangemessage = (e) => {
    setMessage(e.target.value);
    if (e.target.value.trim().length !== 0) {
      setErr(false);
    }
  };
  const getData = (data) => {
    Object.assign(data, { video_review_status: 1 });
    getVideos(data).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        if ((res?.data?.data).length === 0) {
          setEmpty(true);
          setPages(1);
        } else {
          setEmpty(false);
          setPages(res?.data?.data?.pages);
        }
        setnewState1(res?.data?.data);
      } else {
        res?.data?.errors?.map((item) => {
          return toast.error(item);
        });
      }
    });
  };
  const handleClick = (file_id, order_id, type) => {
    setLoading(true);
    if (type === "approve") {
      data = {
        files_id: file_id,
        video_review_status: 2,
        decline_reason: "",
      };
    } else {
      data = {
        files_id: file_id,
        video_review_status: 3,
        decline_reason: message,
      };
    }

    ApproveDecline(data).then((res) => {
      setLoading(false);
      if (res?.data?.status) {
        toast.success(res?.data?.message);
        data = {
          length: length,
          video_review_status: 1,
          start: 0,
        };
        getData(data);
      } else {
        res?.data?.errors?.map((item) => {
          return toast.error(item);
        });
      }
    });
  };
  const handleRequestSort = (event, property) => {
    setLoading(true);
    let sort, sort_column;
    let isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder("desc");
      sort = { sort_by: "asc" };
      sort_column = { sort_column: property };
      setOrderBy(property);
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      sort_column = { sort_column: property };
      sort = { sort_by: order };
    }
    Object.assign(data, sort_column, sort, { start: start });
    getData(data);
  };

  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    Object.assign(data, { start: a });
    getData(data);
  };
  useEffect(() => {
    Object.assign(data, { start: 0 });
    getData(data);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
      }}
    >
      <LoaderComponent open={loading} />

      {Empty && (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 20,
            pb: 20,
            fontSize: "35px",
            color: "#A8A8A8",
            fontWeight: "700",
          }}
        >
          No Record Found
        </Typography>
      )}
      {!Empty && (
        <Box
          sx={{
            bgcolor: "white",
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            width: "100%",
            borderRadius: 4,
            justifyContent: "center",
            mb: 5,
          }}
        >
          <Box sx={{ overflow: "auto", ml: 1, mt: 1 }}>
            <Table sx={{ width: { lg: "100%", xs: "1000px" } }}>
              <EnhancedTableHead
                totalColumn={[
                  "Video",
                  "Order Number",
                  "Scheduled Date",
                  "If Declined tell us why?",
                  "Action",
                ]}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                checkbox={true}
              />
              <TableBody>
                {newState1?.videos?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{ bgcolor: index % 2 === 0 ? "#FFFFFF" : "#FFFAF3" }}
                  >
                    <TableCell sx={{ width: "250px" }}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          sx={{ height: { xs: 100, md: 65 } }}
                          width={60}
                        />
                      )}
                      {!loading && (
                        <Box
                          sx={{
                            height: { xs: 80, md: 100 },
                            maxWidth: 200,

                            border: "1px solid rgba(61, 46, 87, 0.4)",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "7px",
                          }}
                        >
                          <video width="150" height="75" controls>
                            <source src={item?.file_path} />
                          </video>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                      {loading && (
                        <Skeleton variant="rectangular" height={50} />
                      )}
                      {!loading && item?.order_number}
                    </TableCell>
                    <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                      {loading && (
                        <Skeleton variant="rectangular" height={50} />
                      )}
                      {!loading && item.post_on_or_after_date?item.post_on_or_after_date:item.post_date}
                    </TableCell>
                    <TableCell style={Style.tableCell} sx={{ width: "300px" }}>
                      {loading && (
                        <Skeleton variant="rectangular" height={50} />
                      )}
                      {!loading && (
                        <TextareaAutosize
                          minRows={3}
                          onChange={handleChangemessage}
                        />
                      )}
                      {err && item_id===item.order_number &&(
                        <p style={{ color: "red" }}> Please give a reason ! </p>
                      )}
                    </TableCell>
                    <TableCell padding="none">
                      {loading && (
                        <Skeleton variant="rectangular" height={50} />
                      )}
                      {!loading && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: 70,
                          }}
                        >
                          <Button
                            style={Style.linkButton}
                            onClick={() =>
                              handleClick(
                                item.file_id,
                                item.orders_id,
                                "approve"
                              )
                            }
                          >
                            Approve
                          </Button>
                          <Button
                            style={Style.linkButton1}
                            onClick={() => {
                              if (
                                message === "" ||
                                message.trim().length === 0
                              ) {
                                setErr(true);
                                setItem_id(item.order_number)
                              } else {
                                handleClick(
                                  item.file_id,
                                  item.orders_id,
                                  "decline"
                                );
                              }
                            }}
                          >
                            Decline
                          </Button>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Toastify />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              p: 3,
            }}
          >
            {pages > 1 && (
              <Pagination
                count={pages}
                page={page}
                //   boundaryCount={2}
                sx={{ button: { fontSize: "16px" } }}
                onChange={handlePageChange}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderDetail: (data) => dispatch(getOrderDetail(data)),
    getOrderItems: (data) => dispatch(getOrderItems(data)),
    ApproveDecline: (data) => dispatch(ApproveDecline(data)),
    getVideos: (data) => dispatch(getVideos(data)),
  };
}
export default connect(null, mapDispatchToProps)(OrderPending);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "24px",
    bgcolor: "#3D2E57",
    color: "white",
    display: "flex",
    borderRadius: "5px",
    p: 2,
    pl: { xs: 2, sm: 5 },
    mb: 3,
    mt: 3,
    ml: { xs: 1, sm: 3 },
    mr: { xs: 1, sm: 3 },
  },
  typographyStyle1: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginBottom: "10px",
    minWidth: "117.93px",
    color: "#000000",
  },
  tableCell: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#000000",
  },
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 400,
  },
  linkButton: {
    maxWidth: "102px",
    paddingTop: "5.5px",
    paddingBottom: "5.5px",
    borderRadius: "5px",
    backgroundColor: "#27AE60",
    color: "white",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    cursor: "pointer",
  },
  linkButton1: {
    maxWidth: "102px",
    paddingTop: "5.5px",
    paddingBottom: "5.5px",
    borderRadius: "5px",
    backgroundColor: "#EB5757",
    color: "white",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    cursor: "pointer",
  },
};
