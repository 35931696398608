import React from 'react';
import { toast } from "react-toastify";
import OrdersApi from "../../services/orders/index";

export const getOrders = (data) => async (dispatch) => {
    try {
        let response = await OrdersApi.requestOrders(data);
    
        if (response.status) {
          // dispatch(OrdersData(response.data));
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};
export const getVideos = (data) => async (dispatch) => {
  try {
      let response = await OrdersApi.requestgetVideos(data);
  
      if (response.status) {
        // dispatch(OrdersData(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
};
export const getOrderDetail = (data) => async (dispatch) => {
    try {
        let response = await OrdersApi.requestOrderDetail(data);
    
        if (response.status) {
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};

export const getOrderItems = (data) => async (dispatch) => {
    try {
        let response = await OrdersApi.requestOrderItems(data);
    
        if (response.status) {
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};

export const getItemDetail = (data) => async (dispatch) => {
    try {
        let response = await OrdersApi.requestItemDetail(data);
    
        if (response.status) {
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};
export const ApproveDecline = (data) => async (dispatch) => {
  try {
      let response = await OrdersApi.requestApproveDecline(data);
  
      if (response.status) {
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
};
export const updateStatus = (data) => async (dispatch) => {
  try {
      let response = await OrdersApi.requestupdateStatus(data);
  
      if (response.status) {
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
};