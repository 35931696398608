import {
  Box,
} from "@mui/material";
import { useLocation } from 'react-router-dom'
import Form from "./Form";
import React,{useState,useEffect} from "react";

const AddNewEgift = () => {
  const location = useLocation()
    const [Edit,setEdit] = useState(false)
      useEffect(() => {
        if(location.state){
          setEdit(true)
        }
      }, [])
  return (
    <Box>
    <>
      {Edit && <Form Edit={true} egift_id={location.state} />}
      {!Edit && <Form Edit={false} egift_id={location.state} />}
    </>
  </Box>   
  );
};
export default AddNewEgift;
