import { toast } from "react-toastify";
import UserApi from "../../services/users";

export const getdata = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestusersdata(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const getuserdata = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestuserdata(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const saveUser = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestsavedata(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const deleteuserData = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestdeleteUser(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const getReminders = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestReminder(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const getAddresses = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestAddress(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const getUserAddress = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestUserAddress(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const SaveAddressList = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestSaveAddressList(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const country_dropdown_data = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestCountryData(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const updateReminder = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestUpdateReminder(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const removeReminder = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestRemoveReminder(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };
  export const deleteAddress = (data) => async (dispatch) => {
    try {
      let response = await UserApi.requestdeleteAddress(data);
  
      if (response.status) {
        // dispatch(userdata(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
  };