import React from 'react';
import { toast } from "react-toastify";
import EgiftApi from "../../services/eGiftCards/index";

export const getEgiftData = (data) => async (dispatch) => {
    try {
        let response = await EgiftApi.requestEgiftData(data);
    
        if (response.status) {
          // dispatch(OrdersData(response.data));
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};

export const getVideoData = (data) => async (dispatch) => {
    try {
        let response = await EgiftApi.requestVideoData(data);
    
        if (response.status) {
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};

export const saveData = (data) => async (dispatch) => {
    try {
        let response = await EgiftApi.requestSaveData(data);
    
        if (response.status) {
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};

export const getViewEgiftData = (data) => async (dispatch) => {
    try {
        let response = await EgiftApi.requestViewData(data);
    
        if (response.status) {
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};
export const deleteEgift = (data) => async (dispatch) => {
  try {
      let response = await EgiftApi.requestdeleteEgift(data);
  
      if (response.status) {
        // dispatch(OrdersData(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
};
export const egiftamountlist = (data) => async (dispatch) => {
  try {
    let response = await EgiftApi.requestEGiftAmountList(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
}

export const addegiftamount = (data) => async (dispatch) => {
  try {
    let response = await EgiftApi.requestAddEgiftamount(data);

    if (response.status) {
      // dispatch(OrdersData(response.data));
      return response;
    } else {
      response?.data?.errors?.map((item) => {
        return toast.error(item);
      });
      return response;
    }
  } catch (err) {}
}
export const deleteEgiftamount = (data) => async (dispatch) => {
    try {
      let response = await EgiftApi.requestDeleteEgiftamount(data);
  
      if (response.status) {
        // dispatch(OrdersData(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {}
  }
  export const getAmount = (data) => async (dispatch) => {
    try {
      let response = await EgiftApi.requestgetamount(data);
  
      if (response.status) {
        // dispatch(OrdersData(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {}
  }