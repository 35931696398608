/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Skeleton,
} from "@mui/material";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { EnhancedTableHead } from "../../components/Custom/Table/TableHeader";
import { Link, useLocation } from "react-router-dom";
import { FILE_TYPE } from "../../config/mockData";
import { getOrderDetail, getOrderItems, updateStatus } from "../../redux/action/orders";
import StatusDialog from "../../components/Custom/DialogBox/StatusDialog";

const OrderDetail = ({ getOrderDetail, getOrderItems, updateStatus }) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [item_id, setItem_id] = React.useState("");
  const [value, setValue] = React.useState("Pending");
  const [value1, setValue1] = React.useState("Accept");
  const [video, setVideo] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [reason, setReason] = React.useState(false);
  const length = 5;
  let data = {
    orders_id: location.state.id,
    length: length,
  };
  const [newState, setnewState] = useState({
    order_details: {
      order_number: "",
      total_amount: "",
      created_at: "",
      status: "",
    },
  });
  const [newState1, setnewState1] = useState({
    items: [
      {
        item_total_price: "",
        item_qty: "",
        item_title: "",
        item_type_formatted: "",
        img: "",
        file_type: 0,
        status: "",
      },
    ],
    filtered_records: 1,
    total_records: 1,
    pages: 1,
  });
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  const handleChange1 = (e) => {
    setValue1(e.target.value)
    if(e.target.value==="Decline"){
      setReason(true)
    }else{
      setReason(false)
    }
  }
  const submitButton = () => {
    const data = {
      status: video?value1:value,
      orders_id: location.state.id,
      Item_id: item_id,
      message:reason?message:""
    }
    
    updateStatus(data).then((res) => { })
  }
  const handleChangemessage = (e) =>{
   setMessage(e.target.value)
  }
  const getData = (data) => {
    getOrderItems(data).then((res) => {
      setLoading(false);
      if (res.data.data?.order_list?.length === 0) {
        //   setEmpty(true);
        setPages(1);
      }
      if (res.data) {
        //   setEmpty(false);
        setPages(res.data.data.order_items.pages);
        setnewState1(res?.data?.data?.order_items);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    setLoading(true)
    let sort, sort_column;
    let isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder("desc");
      sort = { sort_by: "asc" };
      sort_column = { sort_column: property };
      setOrderBy(property);
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      sort_column = { sort_column: property };
      sort = { sort_by: order };
    }
    Object.assign(data, sort_column, sort, { start: start });
    getData(data);
  };

  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    Object.assign(data, { start: a });
    getData(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    let data1 = { orders_id: location.state.id };
    getOrderDetail(data1).then((res) => {
      setLoading(false);
      if (res.data.data?.order_items?.length === 0) {
        // setPages(1);
      }
      if (res?.data?.status) {
        // setPages(res.data.data.order_items.pages);
        setnewState(res?.data?.data);
      }
    });
    Object.assign(data, { start: 0 });
    getData(data);

  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        width: "100%",
      }}
    >
      <LoaderComponent open={loading} />
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          borderRadius: 4,
          justifyContent: "center",
          mb: 5,
        }}
      >
        <Typography sx={Style.typographyStyle}>Order Detail</Typography>
        {loading && (
          <>
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} />
            <Skeleton
              width={"35%"}
              height={40}
              sx={{ ml: 3 }}
              animation="wave"
            />
            <Skeleton
              width={"35%"}
              height={40}
              sx={{ ml: 3 }}
              animation="wave"
            />
            <Skeleton
              width={"35%"}
              height={40}
              sx={{ ml: 3, mb: 2 }}
              animation={false}
            />
          </>
        )}
        {!loading && (
          <Box
            sx={{
              pl: { xs: 3, sm: 5 },
              pr: 5,
              mt: -1,
              mb: -2,
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mr: { sm: 3, md: 5 },
                ml: { xs: 0, sm: 3 },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
                {newState?.order_details?.customer_name && (
                  <Typography style={Style.typographyStyle1}>
                    Customer name:
                  </Typography>
                )}
                {newState?.order_details?.order_number && (
                  <Typography style={Style.typographyStyle1}>
                    Order Number:
                  </Typography>
                )}
                {newState?.order_details?.total_amount && (
                  <Typography style={Style.typographyStyle1}>
                    Order Total:
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {newState?.order_details?.customer_name && (
                  <Typography style={Style.typographyStyle1}>
                    {newState?.order_details?.customer_name}
                  </Typography>
                )}
                {newState?.order_details?.order_number && (
                  <Typography style={Style.typographyStyle1}>
                    {newState?.order_details?.order_number}
                  </Typography>
                )}
                {newState?.order_details?.total_amount && (
                  <Typography style={Style.typographyStyle1}>
                    ${newState?.order_details?.total_amount}
                  </Typography>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                mr: { sm: 3, md: 5 },
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
                {newState?.order_details?.created_at && (
                  <Typography style={Style.typographyStyle1}>
                    Order Date:
                  </Typography>
                )}
                {newState?.order_details?.status && (
                  <Typography style={Style.typographyStyle1}>
                    Status:
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {newState?.order_details?.created_at && (
                  <Typography style={Style.typographyStyle1}>
                    {newState?.order_details?.created_at}
                  </Typography>
                )}
                {newState?.order_details?.status && (
                  <Typography
                    style={Style.tableCell1}
                    sx={{
                      color:
                        newState?.order_details?.status.toLowerCase() ===
                          "completed"
                          ? "#219653"
                          : "#FF8D2A",
                    }}
                  >
                    {newState?.order_details?.status}
                  </Typography>
                )}
              </Box>
            </Box>
          </Box>
        )}

        <Typography sx={Style.typographyStyle}>Item information</Typography>
        <Box sx={{ overflow: "auto", ml: { xs: 1, sm: 6.2 }, mr: 7, mt: 1 }}>
          <Table sx={{ width: { lg: "100%", xs: "1000px" } }}>

            <EnhancedTableHead
              totalColumn={[
                "Item",
                "",
                "Price",
                "QTY",
                "Type",
                "Status",
                "Action",
              ]}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              checkbox={true}
            />
            <TableBody>
              {newState1?.items?.map((item, index) => (
                <TableRow key={index} sx={{ bgcolor: index % 2 === 0 ? "#FFFFFF" : "#FFFAF3" }}>
                  <TableCell>
                    {loading && (
                      <Skeleton
                        variant="rectangular"
                        sx={{ height: { xs: 100, md: 65 } }}
                        width={60}
                      />
                    )}
                    {!loading && (
                      <Box
                        sx={{
                          height: { xs: 80, md: 65 },
                          maxWidth: "65px",
                          // width: "70%",
                          border: "1px solid rgba(61, 46, 87, 0.4)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "7px",
                        }}
                      >
                        {(item?.file_type === FILE_TYPE.IMAGE ||
                          item?.file_type === FILE_TYPE.GIF) && (
                            <img
                              alt="logo1"
                              style={{ height: 50, width: 40 }}
                              src={item?.img}
                            />
                          )}
                        {item?.file_type === FILE_TYPE.VIDEO && (
                          <video width="60" height="65" controls>
                            <source src={item?.img} />
                          </video>
                        )
                        }
                      </Box>
                    )}
                  </TableCell>
                  <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && ""}
                  </TableCell>
                  <TableCell style={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && "$ " + item?.item_total_price}
                  </TableCell>
                  <TableCell style={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading &&
                      (item?.item_qty === "" ? "__" : item?.item_qty)}
                  </TableCell>
                  <TableCell style={Style.tableCell}>
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && item?.item_type_formatted}
                  </TableCell>
                  <TableCell
                    style={Style.tableCell1}
                    sx={{
                      color:
                        item?.status.toLowerCase() === "completed"
                          ? "#219653"
                          : "#FF8D2A",
                      // cursor: "pointer",
                    }}
                    // onClick={() => {
                    //   setOpen(true);
                    //   setValue(item?.status);
                    //   setValue1("Accept");
                    //   item?.file_type === FILE_TYPE.VIDEO ? setVideo(true) : setVideo(false)
                    //   setItem_id(item?.order_items_id)
                    // }}
                  >
                    {/* <img
                      alt="Edit"
                      src={require("../../assets/edit.png")}
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "5px",
                        cursor: "pointer",
                      }}
                    /> */}
                    {!loading &&
                      item?.status}
                  </TableCell>
                  <TableCell
                    padding="none"
                  >
                    {loading && <Skeleton variant="rectangular" height={50} />}
                    {!loading && (
                      <Link
                        to={`/orders/itemdetail`}
                        state={{ id: item.order_items_id }}
                        style={Style.linkButton}
                      >
                        View
                      </Link>
                    )}
                  </TableCell>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        {video &&
          <StatusDialog
            open={open}
            title={"You can Approve the Recorded video"}
            cancelButton={() =>{ setOpen(false)
            setReason(false)}}
            submitButton={submitButton}
            handleChange={handleChange1}
            label="Accept"
            label1="Decline"
            value={value1}
            reason={reason}
            message={message}
            handleChangemessage={handleChangemessage}
          />
        }
        {!video && <StatusDialog
          open={open}
          title={"You can change the status of this item"}
          cancelButton={() => setOpen(false)}
          submitButton={submitButton}
          handleChange={handleChange}
          label="Pending"
          label1="Completed"
          value={value}
        />}
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            p: 3,
          }}
        >
          {pages > 1 && (
            <Pagination
              count={pages}
              page={page}
              //   boundaryCount={2}
              sx={{ button: { fontSize: '16px' } }}

              onChange={handlePageChange}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getOrderDetail: (data) => dispatch(getOrderDetail(data)),
    getOrderItems: (data) => dispatch(getOrderItems(data)),
    updateStatus: (data) => dispatch(updateStatus(data)),
  };
}
export default connect(null, mapDispatchToProps)(OrderDetail);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "24px",
    bgcolor: "#3D2E57",
    color: "white",
    display: "flex",
    borderRadius: "5px",
    p: 2,
    pl: { xs: 2, sm: 5 },
    mb: 3,
    mt: 3,
    ml: { xs: 1, sm: 3 },
    mr: { xs: 1, sm: 3 },
  },
  typographyStyle1: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginBottom: "10px",
    minWidth: "117.93px",
    color: "#000000"
  },
  tableCell: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 400,
    color: "#000000",
  },
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: 400,
  },
  linkButton: {
    maxWidth: "102px",
    paddingTop: "5.5px",
    paddingBottom: "5.5px",
    borderRadius: "5px",
    backgroundColor: "#FF8D2A",
    color: "white",
    textDecoration: "none",
    fontSize: "16px",
    lineHeight: "19px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    cursor: "pointer",
  },
};
