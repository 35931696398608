import { Box } from "@mui/system";
import React,{useEffect,useState} from 'react'
import { useLocation } from 'react-router-dom'
import AddressForm from "../../components/Custom/Form/AddressForm";
const AddnewAddress = () => {
    const location = useLocation()
    const [Edit,setEdit] = useState(false)
      useEffect(() => {
        if(location.state){
          setEdit(true)
        }
      }, [])
  return (
    <Box>
      <>
        {Edit && <AddressForm EditAddress={true} address_id={location.state} />}
        {!Edit && <AddressForm EditAddress={false} address_id={location.state} />}
      </>
    </Box>
  );
};

export default AddnewAddress;
