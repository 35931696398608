import { Api } from "../../../config/request";

export const requestEgiftData = async (data) => {
    return Api.PostRequest("/admin/egift/gifs/list", data);
  };
  export const requestdeleteEgift = async (data) => {
    return Api.PostRequest("/admin/remove/egift/cards", data);
  };
// export const requestEditData = async (data) => {
//     return Api.PostRequest("/user/login", data);
//   };

export const requestSaveData = async (data) => {
  return Api.PostRequest("/admin/save/egift/card", data);
};

export const requestViewData = async (data) => {
    return Api.PostRequest("/admin/get/egift/card", data);
  };
  export const requestVideoData = async (data) => {
    return Api.PostRequest("/admin/get/video/msg", data);
  };
  export const requestEGiftAmountList = async (data) => {
    return Api.PostRequest("/admin/egift/cards/list", data);
  }; 
  export const requestAddEgiftamount = async (data) => {
    return Api.PostRequest("/admin/save/egift/card", data);
  };
   export const requestDeleteEgiftamount = async (data) => {
    return Api.PostRequest("/admin/remove/egift/cards", data);
  };
   export const requestgetamount = async (data) => {
    return Api.PostRequest("/admin/get/egift/card", data);
  };
const EgiftApi = {
    requestEgiftData,
    // requestEditData,
    requestSaveData,
    requestViewData,
    requestdeleteEgift,
    requestVideoData,
    requestEGiftAmountList,
    requestAddEgiftamount,
    requestDeleteEgiftamount,
    requestgetamount
};
export default EgiftApi;