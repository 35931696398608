import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import GreetingCardCatagories from "../greetingCards/GreetingCardCatagories";
import { makeStyles } from "@material-ui/core/styles";
import GiftCardAmounts from "./GiftCardAmounts";
import AlertDialog from "../../components/Custom/DialogBox/Dialog";
import { useNavigate } from "react-router-dom";
// import GiftCardAmounts from "./GiftCardAmounts";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(() => ({
  tab: {
    background: "#2A22461A",
    fontSize: "18px",
    fontWeight: "400",
    "&.Mui-selected": {
      background: "#3D2E57",
      color: "white",
      borderRadius: "5px",
      borderBottom: 0,
    },
    "& .MuiBox-root": {
      padding: "0px",
    },
    borderRadius: "5px",
    color: "#3D2E57",
    textTransform: "none",
    "&.MuiTab-root.Mui-selected": {
      color: "white",
      textTransform: "none",
    },
    "&.MuiButtonBase-root": {
      textTransform: "none",
    },
  },
  TabPanel: {
    "& .MuiBox-root": {
      padding: "0px",
      textTransform: "none",
    },
  },
}));
export default function Configuration() {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();
  const tabClasses = { root: classes.tab };
  const tabPanelClasses = { root: classes.TabPanel };
  const [model, setModal] = React.useState(false);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  React.useEffect(() => {
    // setModal(true);
  }, []);

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: "Effra",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "24px",
          lineHeight: "29px",
          color: "#3D2E57",
          pb: 2,
        }}
      >
        Configuration
      </Typography>
      <AlertDialog
        title={"This is under progress"}
        text={"Please cancel to go back"}
        // onClickButton={() => deleteUser()}
        onClickButtonCancel={() => {
          setModal(false);
          navigate(-1);
        }}
        open={model}
      />
      <Box sx={{ borderColor: "divider", }}>
        <GiftCardAmounts/>
        {/* <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            borderBottom: "none",
          }}
        >
          <Tab
            classes={tabClasses}
            label="Greeting Card Categories"
            {...a11yProps(0)}
          />
          <Tab
            classes={tabClasses}
            label="Gift Card Amounts"
            {...a11yProps(1)}
          />
        </Tabs> */}
      </Box>
      {/* <TabPanel value={value} index={0} classes={tabPanelClasses}>
        <GreetingCardCatagories />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <GiftCardAmounts />
      </TabPanel> */}
    </Box>
  );
}
