import { combineReducers } from "redux";
import Configuration from "./configuration";
import EgiftCards from "./eGiftCards";
import GiftCards from "./giftCards";
import GreetingCards from "./greetingCards";
import Login from "./login";
import Orders from "./orders";
import Profile from "./profile";
import Users from "./users";

const rootReducer = combineReducers({
  Configuration,
  EgiftCards,
  GiftCards,
  GreetingCards,
  Login,
  Orders,
  Profile,
  Users,
});
export default rootReducer;
