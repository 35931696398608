/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Skeleton,
} from "@mui/material";
import { getItemDetail,updateStatus } from "../../redux/action/orders";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { useLocation } from "react-router-dom";
import { FILE_TYPE } from "../../config/mockData";
import StatusDialog from "../../components/Custom/DialogBox/StatusDialog";

const ItemDetail = ({ getItemDetail,updateStatus }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [defaultValue, setDefaultValue] = React.useState("");
  const [value, setValue] = React.useState("Pending");
  const [value1, setValue1] = React.useState("Accept");
  const [video, setVideo] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [reason, setReason] = React.useState(false);
  const [newState, setnewState] = useState({
    item_total_price: "",
    item_qty: "",
    item_title: "",
    img: "",
    item_type_formatted: "",
    file_type: 1,
    status: "",
    greet_msg: "",
    recipient_name: "",
    recipient_dob: "",
    recipient_email: "",
    recipient_contact: "",
    recipient_address1: "",
    recipient_address2: "",
  });
  const handleChange = (e) => {
    setValue(e.target.value)
  }
  const handleChange1 = (e) => {
    setValue1(e.target.value)
    if(e.target.value==="Decline"){
      setReason(true)
    }else{
      setReason(false)
    }
  }
  const submitButton = () => {
    const data = {
      status: video?value1:value,
      orders_id:location.state.id ,
      Item_id: location.state.id,
      message:message,
    }
    
    updateStatus(data).then((res) => { })
  }
  const handleChangemessage = (e) =>{
   setMessage(e.target.value)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    let data = { order_items_id: location.state.id };
    getItemDetail(data).then((res) => {
      setLoading(false);
      if (res) {
        setnewState(res.data.data.order_item_details);
      }
    });
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignContent: "center",
        pb: 5,
        width: "100%",
      }}
    >
      <LoaderComponent open={loading} />
      <Box
        sx={{
          bgcolor: "white",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          width: "100%",
          borderRadius: 4,
          justifyContent: "center",
        }}
      >
        <Typography sx={Style.typographyStyle}>Order item detail</Typography>

        <Box sx={{ overflow: "auto", ml: 3, mr: 3, mt: -3 }}>
          <Table sx={{ minWidth: { xs: "775px", md: "100%" } }}>
            <TableHead>
              <TableRow>
                <TableCell style={Style.tableCell}>Item</TableCell>
                <TableCell style={Style.tableCell}> </TableCell>
                <TableCell style={Style.tableCell}>Price</TableCell>
                <TableCell style={Style.tableCell}>QTY</TableCell>
                <TableCell style={Style.tableCell}>Type</TableCell>
                <TableCell style={Style.tableCell}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {loading && (
                    <Skeleton
                      variant="rectangular"
                      sx={{ height: { xs: 100, md: 65 } }}
                      width={60}
                    />
                  )}
                  {!loading && (
                    <Box
                      sx={{
                        height: { xs: 100, md: 65 },
                        width: 60,
                        border: "1px solid rgba(61, 46, 87, 0.4)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "7px",
                      }}
                    >
                      {(newState?.file_type === FILE_TYPE.IMAGE ||
                        newState?.file_type === FILE_TYPE.GIF) && (
                        <img
                          alt="logo1"
                          style={{ height: 50, width: 40 }}
                          src={newState?.img}
                        />
                      )}
                      {newState?.file_type === FILE_TYPE.VIDEO && (
                        <video width="60" height="65" controls>
                          <source src={newState?.img} />
                        </video>
                      )}
                    </Box>
                  )}
                </TableCell>
                <TableCell style={Style.tableCell} sx={{ width: "180px" }}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading && ""}
                </TableCell>
                <TableCell style={Style.tableCell}>
                  {loading && <Skeleton variant="rectangular" height={50} />}${" "}
                  {!loading && newState?.item_total_price}
                </TableCell>
                <TableCell style={Style.tableCell}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading &&
                    (newState?.item_qty === "" ? "__" : newState?.item_qty)}
                </TableCell>
                <TableCell style={Style.tableCell}>
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading && newState?.item_type_formatted}
                </TableCell>
                <TableCell
                  style={Style.tableCell1}
                  sx={{
                    color:
                      newState?.status.toLowerCase() === "completed"
                        ? "#219653"
                        : "#FF8D2A",
                    // cursor: "pointer",
                  }}
                  // onClick={() => {
                  //   setOpen(true);
                  //   setValue(newState?.status);
                  //   setValue1("Accept");
                  //   newState?.file_type === FILE_TYPE.VIDEO ? setVideo(true) : setVideo(false)
                  // }}
                >
                  {loading && <Skeleton variant="rectangular" height={50} />}
                  {!loading && newState?.status}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {video &&
          <StatusDialog
            open={open}
            title={"You can Approve the Recorded video"}
            cancelButton={() =>{ setOpen(false)
            setReason(false)}}
            submitButton={submitButton}
            handleChange={handleChange1}
            label="Accept"
            label1="Decline"
            value={value1}
            reason={reason}
            message={message}
            handleChangemessage={handleChangemessage}
          />
        }
        {!video && <StatusDialog
          open={open}
          title={"You can change the status of this item"}
          cancelButton={() => setOpen(false)}
          submitButton={submitButton}
          handleChange={handleChange}
          label="Pending"
          label1="Completed"
          value={value}
        />}
        </Box>

        {loading && (
          <Skeleton
            style={Style.typographyStyle2}
            variant="rectangular"
            animation="wave"
          />
        )}
        {!loading && newState?.greet_msg && (
          <>
            <Typography sx={Style.typographyStyle}>Message</Typography>
            <Typography style={Style.typographyStyle2}>
              {newState?.greet_msg}
            </Typography>
          </>
        )}

        <Typography sx={Style.typographyStyle}>Recipient Details</Typography>

        {loading && (
          <>
            <Skeleton width={"35%"} height={40} sx={{ ml: 3 }} />
            <Skeleton
              width={"35%"}
              height={40}
              sx={{ ml: 3 }}
              animation="wave"
            />
            <Skeleton
              width={"35%"}
              height={40}
              sx={{ ml: 3 }}
              animation="wave"
            />
            <Skeleton
              width={"35%"}
              height={40}
              sx={{ ml: 3, mb: 3 }}
              animation={false}
            />
          </>
        )}
        {!loading && (
          <Box
            sx={{
              mb: 5,
              ml: 5,
              mr: 5,
              display: "flex",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", mr: 2 }}>
              {newState?.recipient_name && (
                <Typography style={Style.typographyStyle1}> Name: </Typography>
              )}
              {newState?.recipient_dob && (
                <Typography style={Style.typographyStyle1}>
                  Date of birth:
                </Typography>
              )}
              {newState?.recipient_email && (
                <Typography style={Style.typographyStyle1}> Email: </Typography>
              )}
              {newState?.recipient_contact && (
                <Typography style={Style.typographyStyle1}>Contact:</Typography>
              )}
              {newState?.recipient_address1 && (
                <Typography style={Style.typographyStyle1}>Address:</Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mr: { sm: 3, md: 5 },
              }}
            >
              {newState?.recipient_name && (
                <Typography style={Style.typographyStyle1}>
                  {newState?.recipient_name}
                </Typography>
              )}
              {newState?.recipient_dob && (
                <Typography style={Style.typographyStyle1}>
                  {newState?.recipient_dob}
                </Typography>
              )}
              {newState?.recipient_email && (
                <Typography style={Style.typographyStyle1}>
                  {newState?.recipient_email}
                </Typography>
              )}
              {newState?.recipient_contact && (
                <Typography style={Style.typographyStyle1}>
                  {newState?.recipient_contact}
                </Typography>
              )}
              {newState?.recipient_address1 && (
                <Typography style={Style.typographyStyle1}>
                  {newState?.recipient_address1}
                </Typography>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    getItemDetail: (data) => dispatch(getItemDetail(data)),
    updateStatus: (data) => dispatch(updateStatus(data))
  };
}
export default connect(null, mapDispatchToProps)(ItemDetail);

const Style = {
  typographyStyle: {
    fontFamily: "Effra",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "24px",
    bgcolor: "#3D2E57",
    color: "white",
    display: "flex",
    borderRadius: "5px",
    p: 2,
    m: 3,
  },
  typographyStyle1: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginBottom: "10px",
    color: "#000000",
  },
  typographyStyle2: {
    fontFamily: "Effra",
    fontSize: "18px",
    lineHeight: "21px",
    fontWeight: "400",
    marginLeft: "24px",
    marginRight: "24px",
    padding: "13px",
    minHeight: "70px",
    color: "#000000",
    backgroundColor: "#F6F6F6",
  },
  tableCell: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
    color: "#000000",
  },
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    fontWeight: 400,
  },
  linkButtonBox: {
    pt: "5px",
    pb: "5px",
    border: "1px solid #3D2E57",
    borderRadius: "15px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    fontSize: "16px",
    textAlign: "center",
  },
  linkButton: {
    color: "#3D2E57",
    textDecoration: "none",
    paddingLeft: "15px",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    width: "100%",
    height: "100%",
  },
};
