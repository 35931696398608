import { toast } from "react-toastify";
import ConfigurationApi from "../../services/configuration/index"


export const getServiceCharges = (data) => async (dispatch) => {
    try {
        let response = await ConfigurationApi.requestServiceCharges(data);
    
        if (response.status) {
          // dispatch(OrdersData(response.data));
          return response;
        } else {
          response?.data?.errors?.map((item) => {
            return toast.error(item);
          });
          return response;
        }
      } catch (err) {
      }
};
export const updateServiceCharges = (data) => async (dispatch) => {
  try {
      let response = await ConfigurationApi.requestUpdateServiceCharges(data);
  
      if (response.status) {
        // dispatch(OrdersData(response.data));
        return response;
      } else {
        response?.data?.errors?.map((item) => {
          return toast.error(item);
        });
        return response;
      }
    } catch (err) {
    }
};