/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  Box,
  TableRow,
  Checkbox,
  Pagination,
  Typography,
  Skeleton
} from "@mui/material";
import TopBox from "../../components/Custom/Boxes/TopBox";
import AlertDialog from "../../components/Custom/DialogBox/Dialog";
import {
  EnhancedTableToolbar,
  EnhancedTableHead,
} from "../../components/Custom/Table/TableHeader";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { egiftamountlist, deleteEgiftamount } from "../../redux/action/eGiftCards";
import LoaderComponent from "../../components/Loader/LoaderComponent";
import { toast } from "react-toastify";
import Toastify from "../../components/SnackBar/Toastify"

const EgiftCardAmount = ({ egiftamountlist, deleteEgiftamount }) => {
  const [loading, setLoading] = useState(true);
  const [deleteData, setDeleteData] = useState();
  const [order, setOrder] = React.useState("asc");
  const [model, setModal] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = React.useState(1);
  const [start, setStart] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [perv_search_val, setPerv_Search_val] = React.useState("");
  const [search_val, setSearch_val] = React.useState("");
  const [Empty, setEmpty] = useState(false);
  const navigate = useNavigate();
  let length = 6;
  let data = {
    start: start,
    length: length,
  };
  const handleRequestSort = (event, property) => {
    setLoading(true);
    let sort, sort_column;
    let isAsc = orderBy === property && order === "asc";
    if (orderBy !== property) {
      setOrder("desc");
      sort = { sort_by: "asc" };
      sort_column = { sort_column: property };
      setOrderBy(property);
    } else {
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
      sort_column = { sort_column: property };
      sort = { sort_by: order };
    }
    Object.assign(data, sort_column, sort);
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }
    getamount();
    setSelected([]);
  };

  React.useEffect(() => {
    getamount();
  }, []);
  const getamount = () => {
    egiftamountlist(data).then((res) => {
      setLoading(false)
      if (res?.data?.data?.total_records === 0) {
        setRows(res.data.data.egift_cards);
        setPages(1);
        setEmpty(true);
      }
      if (res.data.status) {
        setRows(res.data.data.egift_cards)
        setPages(res.data.data.pages)
        setEmpty(false);
      }
    })
  };
  const handleEdit = (id) => {
    navigate("/e-giftcards/amount/update", { state: id });
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((user) => user.file_id);
      setSelected(newSelected);

      const arr1 = newSelected.map((userId) => {
        return { id: userId };
      });
      setDeleteData(arr1);
    } else setSelected([]);
  };
  const handlePageChange = (event, value) => {
    setLoading(true);
    setPage(value);
    let a = (value - 1) * length;
    setStart(a);
    data = {
      start: a,
      length: length,
    };
    if (order && orderBy) {
      let sort_column = { sort_column: orderBy };
      let sort = { sort_by: order };
      Object.assign(data, sort_column, sort);
    }
    if (search_val) {
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
    }
    setSelected([]);
    getamount();
  };
  const handleClick = (event, title) => {
    const selectedIndex = selected.indexOf(title);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, title);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    const arr1 = ([] = newSelected.map((categoryId) => {
      return { id: categoryId };
    }));
    setDeleteData(arr1);
  };

  const isSelected = (title) => selected.indexOf(title) !== -1;
  const onSubmit = () => {
    if ((search_val !== "" && search_val.trim().length !== 0) || perv_search_val !== "") {
      setPerv_Search_val(search_val);
      setLoading(true);
      setStart(0);
      setPage(1);
      data = {
        start: 0,
        length: length,
      };
      let searchVal = { search_val: search_val };
      Object.assign(data, searchVal);
      if (order && orderBy) {
        let sort_column = { sort_column: orderBy };
        let sort = { sort_by: order };
        Object.assign(data, sort_column, sort);
      }
      getamount();
      setSelected([]);
    }
  };
  const deleteUser = () => {
    let id = { egift_cards: deleteData };
    if (deleteData !== undefined || deleteData === []) {
      setLoading(true);
      deleteEgiftamount(id).then((res) => {
        if (res.data.status) {
          setStart(0)
          data = {
            start: 0,
            length: length,
          };
          getamount();
          setSelected([]);
          setModal(false);
          toast.success("Deleted Successfully!!")
        }
        else {
          setModal(false);
          setSelected([]);
          toast.error(res.data.message)
          setLoading(false);

        }
      });
    }
  };

  return (
    <>
      <TopBox
        button_one={"Search"}
        button_two={"Delete"}
        button_three={"Add new"}
        onClick="/e-giftcards/amount/update"
        onSubmit={onSubmit}
        perv_search_val={perv_search_val}
        search_val={search_val}
        setSearch_val={setSearch_val}
        numSelected={selected.length}
        deleteAPI={() => {
          if (deleteData !== undefined) setModal(true);
        }}
      />
      <AlertDialog
        title={"You are about to delete E-Gift Card"}
        text={"This will delete the E-Gift Card from the list"}
        onClickButton={() => deleteUser()}
        onClickButtonCancel={() => setModal(false)}
        open={model}
      />
      <LoaderComponent open={loading} />

      {Empty && (
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 20,
            pb: 20,
            fontSize: "35px",
            color: "#A8A8A8",
            fontWeight: "700",
          }}
        >
          No E-Gift Card Found
        </Typography>
      )}
      {!Empty && (
        <Box
          sx={{
            overflow: "auto",
          }}
        >
          <Table
            sx={{
              width: { lg: "100%", xs: "1000px" },
            }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              totalColumn={["Amount", "Active", "Action"]}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const isItemSelected = isSelected(row.ecard_id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.ecard_id}
                    selected={isItemSelected}
                    sx={{ bgcolor: index % 2 === 0 ? "#FFFFFF" : "#FFFAF3" }}
                  >
                    <TableCell style={Style.tableCell}>
                      <Checkbox
                        onClick={(event) =>
                          handleClick(event, row.ecard_id)
                        }
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      sx={{ width: "300px", pl: 2 }}
                      style={Style.tableCell}
                    >
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={60}
                          width={50}
                        />
                      )}
                      {!loading && row.price}

                    </TableCell>

                    <TableCell style={Style.tableCell}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && row.active_formatted}
                    </TableCell>
                    <TableCell style={{
                      display: 'flex',
                      height: 100,
                      alignItems: 'center',
                      pl: 5,
                      borderBottom: 'none'
                    }}>
                      {loading && (
                        <Skeleton
                          variant="rectangular"
                          height={50}
                          width={100}
                        />
                      )}
                      {!loading && (
                        <>
                          <img
                            src={require("../../assets/edit.png")}
                            style={{
                              width: "18px",
                              height: "18px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleEdit(row.ecard_id);
                            }}
                          />
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          p: 3,
        }}
      >
        {pages > 1 && (
          <Pagination
            count={pages}
            page={page}
            boundaryCount={1}
            onChange={handlePageChange}
            sx={{ button: { fontSize: '16px' } }}

            siblingCount={0}
          />
        )}
      </Box>
      <Toastify />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    egiftamountlist: (data) => dispatch(egiftamountlist(data)),
    deleteEgiftamount: (data) => dispatch(deleteEgiftamount(data)),
  };
};

export default connect(null, mapDispatchToProps)(EgiftCardAmount);

const Style = {
  tableCell1: {
    fontFamily: "Effra",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    // flex:'wrap',
    lineHeight: "28px",
    // height: "56px",
    color: "#333333",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    maxWidth: "200px",
    border: "none",
  },
  tableCell: {
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "19px",
    color: "#333333",
    border: "none",
  },
};
