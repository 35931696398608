import React, { useEffect,useState } from 'react';
import { Box } from '@mui/material';
import { connect } from "react-redux";
import CardView from '../../components/Custom/ViewCardDetail/CardView';
import { getEditViewCardList } from '../../redux/action/greetingCards';
import { useLocation } from 'react-router-dom';


const ViewCardListing = ({getEditViewCardList}) => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [newState,setNewState] = useState({
    title: "",
    price: "",
    description: "",
    is_active: 0,
    main_file: {main_file_path:"",main_file_id: ""},
    pc_file: {pc_file_path:"",pc_file_id: ""},
    more_files: []
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    let data = { card_id: location.state };
    getEditViewCardList(data).then((res)=>{
      setLoading(false);
      if(res?.data?.status){
       setNewState(res?.data?.data);
      }
    });
  }, []);

  return (
    <Box>
       <CardView
        load={ loading }
        title={newState?.title}
        price={newState?.price}
        description={newState?.description}
        isActive={newState?.is_active} 
        mainImg={newState?.main_file?.main_file_path}
        pcImg={newState?.pc_file?.pc_file_path}
        moreImg={newState?.more_files}
       />
    </Box>
  )
}

function mapDispatchToProps(dispatch) {
  return {
    getEditViewCardList: (data) => dispatch(getEditViewCardList(data)),
  };
}
export default connect(null, mapDispatchToProps)(ViewCardListing);