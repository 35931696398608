import React, { useEffect, useState } from "react";
import { Box, Typography, Button, FormLabel, ToggleButtonGroup, ToggleButton, Chip, TextField, Skeleton, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Toastify from "../../components/SnackBar/Toastify";
import { addgiftamount, getAmount } from "../../redux/action/giftCards";
import LoaderComponent from "../../components/Loader/LoaderComponent";
const schema = yup.object().shape({
  price: yup
    .string()
    .required("Please enter Amount")
    .test("Is positive?", "ERROR: The number must be greater than 0!", (value) => value > 0),
});

const AddGiftCard = ({ addgiftamount, getAmount }) => {
  const useStyles = makeStyles((theme) => ({
    inputStyle: {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        display: "none",
      },
      paddingTop: "5px",
    },
  }));
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const [Edit, setEdit] = useState(false);
  const [alignment, setAlignment] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [editdata, setEditData] = useState({
    price: "",
  });
  const { control, formState, handleSubmit, setError } = useForm();
  const { isValid, dirtyFields, errors } = formState;
  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state) {
      setEdit(true);
      setLoading(true);
      getamount();
    }
  }, []);
  let api_data = {
    gift_card_id: location.state,
  };
  const getamount = () => {
    getAmount(api_data).then((res) => {
      setLoading(false);
      if (res.data.status) {
        setEditData({ price: res.data.data.price });
        setAlignment(res.data.data.active_formatted);
      }
    });
  };
  const formik = useFormik({
    initialValues: editdata,
    validationSchema: schema,
    onSubmit: (value) => {
      setEditData(value);
      onSubmit(value);
    },
    enableReinitialize: true,
  });
  const onSubmit = async (model) => {
    let item = model;
    item.is_active = alignment === "Active" ? 1 : 0;
    item.active_formatted = alignment === "Active" ? "Active" : "In-Active";
    //   if (item) {
    Object.assign(item, { gift_card_id: location.state });
    setLoading(true);
    addgiftamount(item).then((res) => {
      setLoading(false);
      if (res.data.status) {
        toast.success("Updated Successfully!!");
        setTimeout(() => {
          navigate("/giftcards");
        }, 2000);
      } else {
        res.data.errors.map((err) => {
          toast.error(err);
        });
      }
    });
  };
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <form style={{ minHeight: "600px" }} name="RegisterForm" onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: "100%",
          height: "30%",
          borderRadius: 2,
        }}>
        <Typography
          sx={{
            fontFamily: "Effra",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "29px",
            pl: 2,
            pb: 2,
            color: "#000000",
          }}>
          {Edit ? "Edit Amount" : "Add New Amount"}
        </Typography>
        <Toastify />
        <Box sx={Style.rowBoxStyle}>
          {loading && <Skeleton variant="rectangular" height={60} width={"47%"} sx={{ mt: 4 }} />}
          {!loading && (
            <Box sx={Style.inputStyle}>
              <FormLabel sx={Style.label}>
                Amount
                <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Controller
                name="price"
                control={control}
                sx={{
                  pb: 0,
                  // mt: 10,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    // InputProps={{  }}
                    className={classes.name}
                    name="price"
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="number"
                    variant="filled"
                    InputProps={{
                      disableUnderline: true,
                      classes: { input: classes.inputStyle },
                    }}
                    autoComplete="false"
                    inputProps={{
                      style: {
                        paddingTop: "18px",
                        paddingBottom: "18px",
                      },
                      min: 0,
                    }}
                    color="primary"
                    placeholder="Enter price"
                    sx={{
                      width: "100%",
                      border: "none",
                    }}
                  />
                )}
              />
              {formik.errors.price && formik.touched.price ? <p style={{ color: "red" }}>{formik.errors.price}</p> : null}
            </Box>
          )}
          <Box
            sx={{
              width: {
                xs: "90%",
                sm: "90%",
                md: "47%",
                lg: "47%",
                xl: "47%",
              },
              mb: 2,
              // pl: 3,
              display: "flex",
              flexDirection: "column",
            }}>
            {loading && (
              <Skeleton
                variant="rectangular"
                height={60}
                width={250}
                sx={{
                  ml: 3,
                  mt: 4,
                }}
              />
            )}
            {!loading && (
              <Box
                sx={{
                  pl: { xs: 2, sm: 3 },
                  display: "flex",
                  flexDirection: "column",
                }}>
                <FormLabel sx={Style.label}>
                  Is Active
                  <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="active"
                  control={control}
                  sx={{
                    pb: 0,
                    // mt: 10,
                  }}
                  render={() => (
                    <ToggleButtonGroup
                      color="primary"
                      value={alignment}
                      exclusive
                      onChange={handleChange}
                      sx={{ height: 56 }}
                      aria-label="Is Active">
                      <ToggleButton color="primary" value="Active" sx={{ width: "64px" }}>
                        Yes
                      </ToggleButton>
                      <ToggleButton color="standard" value="In-Active" sx={{ width: "64px" }}>
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "90%", sm: "92%", md: "35%", lg: "40%" },
          float: "right",
          display: "flex",
          justifyContent: { xs: "space-between", md: "flex-end" },
          pt: 4,
          mr: { xs: 2, md: 2, lg: 3 },
        }}>
        <Button
          disableRipple
          sx={{
            mr: { md: 3 },
            pl: "25px",
            pr: "25px",
            pt: "10px",
            pb: "10px",
            fontSize: "18px",
            lineHeight: "21px",
            fontWeight: 400,
            borderRadius: "5px",
            textTransform: "none",
            border: "1px solid #EB5757",
            bgcolor: "#EB5757",
            color: "white",
            "&.MuiButtonBase-root:hover": {
              border: "1px solid #EB5757",
              bgcolor: "#EB5757",
              color: "white",
            },
          }}
          variant="outlined"
          className="btn"
          onClick={() => {
            navigate("/giftcards");
          }}>
          Cancel
        </Button>
        <LoaderComponent open={loading} />
        <Button
          disableRipple
          sx={{
            pl: "25px",
            pr: "25px",
            pt: "10px",
            pb: "10px",
            fontSize: "18px",
            lineHeight: "21px",
            fontWeight: 400,
            borderRadius: "5px",
            textTransform: "none",
            color: "white",
            bgcolor: "#27AE60",
            border: "1px solid #27AE60",
            "&.MuiButtonBase-root:hover": {
              border: "1px solid #27AE60",
              color: "white",
              bgcolor: "#27AE60",
            },
          }}
          variant="outlined"
          className="btn"
          type="submit">
          Save
        </Button>
      </Box>
    </form>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    addgiftamount: (data) => dispatch(addgiftamount(data)),
    getAmount: (data) => dispatch(getAmount(data)),
  };
}
export default connect(null, mapDispatchToProps)(AddGiftCard);
const Style = {
  typographyStyle: {
    fontSize: "26px",
    fontWeight: "700",
    lineHeight: "31px",
    letterSpacing: "0em",
    textAlign: "center",
    color: "#3D2E57",
    pb: 3,
  },
  rowBoxStyle: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  inputStyle: {
    width: {
      xs: "90%",
      sm: "90%",
      md: "47%",
      lg: "47%",
      xl: "47%",
    },
    mb: 2,
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    color: "#333333",
  },
};
